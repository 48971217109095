import {Box, Button, IconButton, Modal, Typography} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';

/**
 * Bottom action sheet modal
 */
export default function ActionModal({open, onClose, actions, handleAction}) {
  const classes = useStyles();
  const _actions = actions ?? [];
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box className={classes.root}>
      {
        _actions.map(action => (
          <Button className={classes.action} key={action.key} onClick={() => handleAction(action.key)}>
            <Box>
              <Typography className={classes.actionText}>{action.title}</Typography>
            </Box>
          </Button>
        ))
      }
        <Button className={classes.cancelBox} onClick={onClose}>
          <Typography className={classes.cancelText} >
            Cancel
          </Typography>
        </Button>
      </Box>
    </Modal>
  )
}

ActionModal.propTypes = {
  /** Whether the modal is visible or not */
  open: PropTypes.bool,
  /** Function to call when the modal gets closed */
  onClose: PropTypes.func,
  /** List of available actions */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      /** action's identifier */
      key: PropTypes.string,
      /** action's title */
      title: PropTypes.string
    })
  ), 
  /** Handler function when an action is invoked */
  handleAction: PropTypes.func
}

ActionModal.defaultProps = {
  open: false
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    bottom: 48,
    width: '100%',
    maxWidth: '100vw',
    // backgroundColor: 'white',
    padding: '24px 16px',
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
  },
  action: {
    backgroundColor: 'white',
    height: 48,
    marginBottom: 8,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.8)',
      borderColor: 'white',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgba(255,255,255,0.8)',
      borderColor: 'white',
    },
  },
  actionText: {
    color: '#8F01FF',
    fontSize: 15,
    fontWeight: '600',
    textTransform: 'capitalize'
  },
  cancelBox: {
    backgroundColor: 'white',
    height: 48,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.8)',
      borderColor: 'white',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgba(255,255,255,0.8)',
      borderColor: 'white',
    },
  },
  cancelText: {
    color: '#698DD3',
    fontSize: 15,
    fontWeight: '600',
    textTransform: 'capitalize'
  }
}));