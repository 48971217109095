import { MOMENT_SET_DATA } from "../actionTypes";

const DEFAULT_STATE = {
	data: [],
}

export default (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case MOMENT_SET_DATA: {
			return { ...state, ...action.payload };
		}

		default: return state;
	}

//   return state;
}
