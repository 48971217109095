import { PROFILE_SET_DATA } from "../actionTypes"

const setProfileData = payload => {
  return ({
    type: PROFILE_SET_DATA,
    payload
  })
}

export default {
  setProfileData
}