import React from "react";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import ProfilesPage from "../components/Views/TopProfiles/TopProfiles";
import Sidebar from "../components/Navigation/Sidebar";


import routes from "../routes";

import styles from "../assets/jss/adminStyle";


let ps;

const useStyles = makeStyles(styles);

export default function Home({ ...rest }) {
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);


  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"Tagg"}
        logo={null}
   
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"black"}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        {/* <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        /> */}
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              <Route
                exact
                path={"/home/profiles"}
                component={ProfilesPage}
                key={1}
              />
              
            </Switch>
          </div>
        </div> 
      </div>
    </div>
  );
}
