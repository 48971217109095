import React, { useEffect } from 'react';
import {Box, Grid, Hidden} from '@mui/material';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';

import MobileBottomNav from '../Navigation/MobileBottomNav';
import PagesBar from '../components/PagesBar';
import Taggs from '../components/Taggs';
import { loadPageContent } from '../../services';
import MomentPreview from '../components/MomentPreview';
import { momentAction } from '../../redux/actions';


const useStyles = makeStyles((theme) => ({
  background: {
    paddingBottom: 12
  },
  moments: {
    color: props => {
      if (props.secondary_color) return props.secondary_color;
      switch (props.template_type) {
        case "ONE":
          return "#892101";
        case "TWO":
          return "#698DD3";
        default:
          return "white";
      }
    }
  }
}));


/**
 * User's category page
 */
export default function CategoryPage({userData, page}) {
  const [taggs, setTaggs] = React.useState([]);
  const [moments, setMoments] = React.useState([]);
  const [loading, setLoading] = React.useState(true)
  const classes = useStyles(userData.skin);
  const history = useHistory();
  const dispatch = useDispatch();

  async function getTaggsAndMoments() {
    if (page !== "") {
      try {
        setLoading(true);
        const data = await loadPageContent(userData.user.username, page);
        setTaggs(data.widgets);
        setMoments(data.moments);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  }
  
  useEffect(() => {
    getTaggsAndMoments();
  }, [page])

  const onClickMoment = (idx) => {
    dispatch(momentAction.setMomentsData({data: moments}));
    history.push(`/moments/${userData.user?.username}/${page}/${idx}`);
  }

  return (
    loading ? <p>Loading...</p> : 
    <Box className={classes.background}>
      <Grid container spacing={0}>
        <Hidden mdUp>
          <PagesBar userData={userData} active={page} />
        </Hidden>
        {
          taggs && !!taggs.length &&
          <Taggs isHome={false} userData={userData} taggs={taggs}/>
        }
        <Grid className={classes.moments }item xs={12} sx={{marginLeft:"8px"}}>
          <h3>Moments</h3>
        </Grid>
        <Grid container spacing={0}>
            {moments.map((moment, idx) => {
              return (
                <Grid key={idx} item xs={4} md={4} onClick={() => onClickMoment(moment.moment_id)}>
                  <MomentPreview key={idx} thumbNail={moment.thumbnail_url} />
                </Grid>
              );
            })}
        </Grid>     
        {/* <Hidden mdUp>
          <MobileBottomNav />
        </Hidden> */}
      </Grid>  
    </Box>
  )
}

CategoryPage.propTypes = {
  /** User's data including name, skin etc */
  userData: PropTypes.object.isRequired,
  /** Current category */
  page: PropTypes.string.isRequired
}