import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import {Img} from 'react-image'
import PropTypes from 'prop-types';
import Coin from '../../assets/img/Coinprofile.png';

const useStyles = makeStyles((theme) => ({
  userBox: {
    background: "transparent",
    height: "164px", 
    borderRadius: "10px",
    display: 'flex',
    alignItems: 'center'
  },
  outerBox: {
    marginTop: props => {
      if (props.template_type === "FOUR") {
        return "8px";
      } else {
        return null;
      }
    }
  },
  bioText: {
    color: props => {
      return props.bio_text_color || "white"
    },
    fontSize: 13, 
    fontWeight: '500',
    maxHeight: 100,
    overflow: 'hidden'
  },
  taggScoreText: {
    marginTop: 24,
    fontSize: 13,
    fontWeight: '700',
    display:"flex", 
    alignItems:"center",
    fontFamily: 'SF Pro Text Bold',   
    color: props => {
      return props.secondary_color || 'white'
    }
  }
}));

/**
 * Shows user's data including picture, tagg score, biography etc<br>
 * This is used for template 5
 */
export default function UserBox2({userData}) {
  const classes = useStyles(userData.skin);
  return (
   
    <Grid className={classes.outerBox} sx={{margin:'8px', marginBottom:'0px'}} item xs={12} md={12}>
      <Box className={classes.userBox}>
        <Box>
          <Img src={userData.user.profile_pic} style={{margin:'auto', objectFit:'cover', objectPosition:'top' }} 
            height="144px" width="117px" alt="Profile pic" 
            loader={<img style={{margin:'auto', backgroundColor:"#C4C4C4"}} height="144px" width="117px" src="/img/missing.png"  alt="Profile pic" /> }
            unloader={<img style={{margin:'auto', backgroundColor:"#C4C4C4"}} height="144px" width="117px" src="/img/missing.png" alt="Profile pic" /> } />
        </Box>
        <Box style={{margin:"15px", justifyContent:'center', textAlign:'left', maxHeight: "164px", maxWidth:"190px", display:""}}>
          <Typography className={classes.bioText}>{userData.user.biography ? userData.user.biography : null}</Typography>
          <Typography className={classes.taggScoreText}> 
            <span style={{fontFamily: 'SF Pro Text Regular', paddingRight: '4px', fontWeight: '400'}}>Coins Earned:</span>
            {userData.tagg_score}
            <img src={Coin} style={{margin:"2px 0 0 2px"}} alt="Coin" width={18} height={18}/>
          </Typography>
        </Box>
      </Box>
    </Grid>
  
  );
}

UserBox2.propTypes = {
  /** User's data including picture, tagg score and biography */
  userData: PropTypes.object.isRequired
}