import { API_URL } from "./config";

// Example endpoint
// https://app-prod3.tagg.id/api/web/page?username=pat144&page=Sprain

const TAGGS_AND_MOMENTS_ENDOINT = API_URL+"page?";

export const loadPageContent = async (userName, page) => {
  try {
    const response = await fetch(TAGGS_AND_MOMENTS_ENDOINT + `username=${userName}`
      + `&page=${page}`, {
      method: 'GET',
    });
    const status = response.status;
    if (status === 200) {
      const data = await response.json();
      return data;
    } else {
      alert('Unable to load profile data');
    }
  } catch (error) {
    alert("Something went wrong. Please refresh the page");
  }
};