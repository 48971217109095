export const BASE_URL = "https://app-prod3.tagg.id/"; //'http://18.118.96.54/'; // "https://app-prod2.tagg.id/"; // 'http://localhost:8000/';
export const API_URL = BASE_URL + 'api/web/';

export const APPSTORE_URL = 'https://apps.apple.com/us/app/tagg-powered-by-taggid-inc/id1537853613';
export const APP_LINK = { iOS: 'https://redirect.tagg.id/join' };

export const WidgetType = {
  VIDEO_LINK: 'VIDEO_LINK',
  APPLICATION_LINK: 'APPLICATION_LINK',
  GENERIC_LINK: 'GENERIC_LINK',
  RECENT_MOMENT: 'RECENT_MOMENT',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA'
}

export const WidgetSubType = {
  VIDEO_LINK: {
    YOUTUBE: "YOUTUBE",
    TIKTOK: "TIKTOK",
    TWITCH: "TWITCH",
    VIMEO: "VIMEO",
  },
  APPLICATION_LINK: {
    SPOTIFY: "SPOTIFY",
    SOUNDCLOUD: "SOUNDCLOUD",
    APPLE_MUSIC: "APPLE_MUSIC",
    APPLE_PODCAST: "APPLE_PODCAST",
    POSHMARK: "POSHMARK",
    DEPOP: "DEPOP",
    ETSY: "ETSY",
    SHOPIFY: "SHOPIFY",
    AMAZON: "AMAZON",
    APP_STORE: "APP_STORE",
  },
  GENERIC_LINK: {
    
  },
  RECENT_MOMENT: {
    
  },
  SOCIAL_MEDIA: {
    FACEBOOK: "FACEBOOK",
    INSTRGAM: "INSTAGRAM",
    TWITTER: "TWITTER",
    SNAPCHAT: "SNAPCHAT",
    TIKTOK: "TIKTOK",
  }
}

export const MixPanel = {
  random: "5f393086e14172281e6636b7fe6059c2"
}