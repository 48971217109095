/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, useLocation, Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import {Box, List, ListItemText, ListItem} from "@material-ui/core";
import { ArrowBackIos } from '@mui/icons-material';
import LogoName from '../../assets/img/LogoName.png';
import MomentIcon from "../../assets/img/DiscoverMoment.png";
import Profile from "../../assets/img/Profile.png";
import styles from "../../assets/jss/sidebarStyle";

const useStyles = makeStyles(styles);

/**
 * Sidebar of main pages
 */
export default function Sidebar(props) {
  const [userName, setUserName] = useState('');
  const [page, setPage] = useState('');
  const location = useLocation();

  useEffect(() => {
    let pathSegments = location.pathname.split('/');
    if(pathSegments.length > 3) {
    } else {
      setPage('Home');
    }

    setUserName(pathSegments[2]);
  }, [location.pathname]);

  const classes = useStyles();
  const { routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {

        return (
          <NavLink
            exact
            to={prop.path}
            className={classes.item}
            activeClassName="active"
            key={key}
          >
            <Box className={classes.itemLink}>
              <img src={prop.icon} />
              <ListItemText
                primary={prop.name}
                className={classNames(classes.itemText)}
                disableTypography={true}
              />
            </Box>
          </NavLink>
        );
      })}
    </List>
  );
  

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"left"}
          open={props.open}
          
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
       
          <div className={classes.sidebarWrapper}>
          <List className={classes.list}>
              {/* <NavLink
                exact
                to="/home/profiles"
                className={classes.item}
                activeClassName="active"
                onClick={props.handleDrawerToggle}
              >
                <ListItem button className={classes.itemLink}>
                  <img src={Profile} height="25px" width="25px" alt="Profile Icon"/>
                  <ListItemText
                    primary="Profiles"
                    className={classNames(classes.itemText)}
                    disableTypography={true}
                  />
                </ListItem>
              </NavLink>
              <NavLink
                exact
                to="/home/moments"
                className={classes.item}
                activeClassName="active"
                onClick={props.handleDrawerToggle}
              >
                <ListItem button className={classes.itemLink}>
                  <img src={MomentIcon} height="25px" width="25px" alt="Moment Icon"/>
                  <ListItemText
                    primary="Moments"
                    className={classNames(classes.itemText)}
                    disableTypography={true}
                  />
                </ListItem>
              </NavLink> */}
              
       
            </List>
          </div>
          
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={"left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper)
          }}
        >
          { page !== 'Home' ? 
          <Link to={`/profiles/${userName}`} style={{color: 'white', textTransform: 'none', fontSize: 18, fontWeight: '700', textDecoration: 'none', display: 'flex', alignItems:'center',padding: '30px 24px 24px'}}><ArrowBackIos sx={{color: "white", fontSize: 16}} />Home</Link> 
          : page == 'Home' ? <img className={classes.logo} src={LogoName} alt="Tagg"/> : null}
          
          <div className={classes.sidebarWrapper}>
            <List className={classes.list}>
              {/* <NavLink
                exact
                to="/home/profiles"
                className={classes.item}
                activeClassName="active"
              >
                <ListItem button className={classes.itemLink}>
                  <img src={Profile} height="25px" width="25px" alt="Profile Icon"/>
                  <ListItemText
                    primary="Profiles"
                    className={classNames(classes.itemText)}
                    disableTypography={true}
                  />
                </ListItem>
              </NavLink>
              <NavLink
                exact
                to="/home/moments"
                className={classes.item}
                activeClassName="active"
       
              >
                <ListItem button className={classes.itemLink}>
                  <img src={MomentIcon} height="25px" width="25px" alt="Moment Icon"/>
                  <ListItemText
                    primary="Moments"
                    className={classNames(classes.itemText)}
                    disableTypography={true}
                  />
                </ListItem>
              </NavLink>
               */}
       
            </List>
            </div>
          
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  /** Function to toggle drawer visibility */
  handleDrawerToggle: PropTypes.func,
  /** Available routes from sidebar */
  routes: PropTypes.arrayOf(PropTypes.object),
  /** Whether it is visible or not on mobile browser */
  open: PropTypes.bool,
};