import React from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import playButton from '../../assets/img/Widgets/play.png'

/**
 * Shows video, and play automatically when it comes to be visible
 */
const MyVideo = function({src, className, isCurrent,  index}) {
  const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari']);
  const { ref, inView, entry } = useInView({
    threshold: 0.01, 
    // trackVisibility: true,
    // delay: 100
  });
  const refVideo = React.useRef();
  const [videoIcon, setVideoIcon] = React.useState();
  const [isMute, setMuteState] = React.useState(true);
  
  React.useEffect(() => {
      //Make it paused by default

      try {
      
        if (!isCurrent) {
          refVideo.current?.pause();
          setVideoIcon(true);
        } else {
          setVideoIcon(false);
          const PromisePlay = refVideo.current?.play();
          
          PromisePlay.catch(() => {
            refVideo.current?.pause();
            setVideoIcon(true);
          });

        }
      } catch (e) {
        console.log("Unable to autoplay with audio");
      }

    }, [isCurrent, index])

  const togglePlayPause = () => {
   
    let paused = refVideo.current.paused;

    if (paused) {
        setVideoIcon(false);
        refVideo.current.play();
    } else {
      refVideo.current.pause();
      setVideoIcon(true);
    }

  }

  return (
    <div ref={ref} className={className} style={{position: 'relative'}}>
      <video playsInline loop muted={isSafari ? true : false} ref={refVideo} src={src} width="100%" height="100%" className={className}/>
      <div style={styles.overlay} className="abc" onClick={togglePlayPause}>
      {videoIcon ? <img style={styles.play} src={playButton} alt="play" width={50}/> : null }
      </div> 
    </div>
  )
}

MyVideo.propTypes = {
  /** source url */
  src: PropTypes.string.isRequired,
  /** CSS classnames */
  className: PropTypes.string,
  /** Whether it is currently visible or not */
  isCurrent: PropTypes.bool
}

MyVideo.defaultProps = {
  isCurrent: false
}

export default MyVideo;

const styles = {
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  play: {
      position:'relative',
      top:'50%',
      left:'50%',
      transform: 'translate(-50%, -50%)'
  },

  btn: {
    position: 'relative',
    top: '15%',
    left: '3%',
    padding: "8px 10px",
    border: 'none',
    borderRadius: '8px',
    opacity: 0.9,
    fontFamily: 'SF Pro Text Medium',
    zIndex: 9999
  },

  pauseBtn: {
    "display": "inline-block",
  }
}