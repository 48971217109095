import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import classNames from "classnames";

import PropTypes from "prop-types";
import headerStyle from "../../assets/jss/headerStyle";


const useStyles = makeStyles(headerStyle);

/**
 * Navigation bar on mobile browser
 */
export default function Navbar({color, handleDrawerToggle}) {
  const classes = useStyles();

  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });
  return (
    <Hidden mdUp >
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        
          <div className={classes.flex}>
            <h2>
              Tagg
            </h2>
          </div>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
      </Toolbar>
    </AppBar>
    </Hidden>
  );
}

Navbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  handleDrawerToggle: PropTypes.func,
};
