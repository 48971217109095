import {useState} from 'react'
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import Nav from "../../components/Static/Nav";
import Lottie from "lottie-react";
//import BackgroundAnimation from "../../assets/images/lottie-bg-animation.json";
import BackgroundAnimation from "../../assets/images/tagg-home.json";
import routes from "../../routes";

export default function FrontPage({ ...rest}) {
  const [toggle, setToggle] = useState('0')
  return (
    <div style={{position:"relative"}}>
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="800"
        data-easing2="ease-in-out"
        data-easing="ease-in-out"
        role="banner"
        class="navbar w-nav mainNav"
      >
        <a href="/" class="brand">
          <img
            src="./img/logo-written-white-05.png"
            loading="lazy"
            width="80px"
            sizes="80px"
            srcset="./img/logo-written-white-05-p-500.png 500w, ./img/logo-written-white-05-p-800.png 800w,  ./img/logo-written-white-05-p-1080.png 1080w, ./img/logo-written-white-05-p-1600.png 1600w, ./img/logo-written-white-05-p-2000.png 2000w, ./img/logo-written-white-05.png"
            alt=""
            class="image-logo"
          />
        </a>
        
      </div>

      <div class="tagg-intro-section wf-section no-img banner">
        <div class="tagg-intro-hero">
          <div class="tagg-intro-container">
            <h1 class="tagg-heading-1">
            Earn for engagement
            </h1>
            {/* <p class="tagg-paragraph">Get Engagement, Earn rewards</p> */}
            <div class="interaction-div">
              {/* <a
                href="https://apps.apple.com/us/app/tagg-powered-by-taggid-inc/id1537853613"
                class="button w-button"
              >
                Manifest your Profile
              </a>
              <a
                href="https://apps.apple.com/us/app/tagg-powered-by-taggid-inc/id1537853613"
                target="_blank"
                class="app-link w-inline-block"
              >
                <img
                  src="./img/Download-on-app-store.png"
                  loading="lazy"
                  alt=""
                  class="image-2"
                />
              </a> */}
              <link
                rel="prerender"
                href="https://apps.apple.com/us/app/tagg-powered-by-taggid-inc/id1537853613"
              />
            </div>
          </div>
          <div class="tagg-mockup">
            <img
              src="./img/cosmo-mid.png"
              loading="lazy"
              alt=""
              class="image-3"
            />
          </div>
        </div>
      </div>

      <div class="section-3 wf-section bannerBG">
        <div class="div-block-6">
          <Lottie
            animationData={BackgroundAnimation}
            loop={true}
            autoplay={true}
            id="svg"
          />
        </div>
      </div>

      <div class="rcc-section">
        <div class="w-container">
          <div class="rcc-content">
            <h2>Rewarding Creators for Creating</h2>
            <p>
            Tagg’s mission is to bolster forward the Gen-Z creator economy by pushing for innovation in the ways content-creators grow and more importantly, EARN.
            </p>
            <img src="./img/Rewarding-creators-image.png" />
          </div>
        </div>
      </div>

      <div class="section-11 wf-section">
        <div class="w-container">
          <div class="div-block-42">
            <h2 class="heading-16-copy">As seen on</h2>
          </div>
          <div class="w-layout-grid grid-18">
            <a
              id="w-node-cbe59c7b-df41-2047-debf-defad6155808-8ca299e2"
              href="https://techcrunch.com/2021/12/17/backed-by-former-facebook-and-twitter-execs-tagg-launches-social-branding-app-for-gen-z/"
              target="_blank"
              class="w-inline-block"
            >
              <img
                src="./img/Group-664.png"
                loading="lazy"
                id="w-node-_8d11d300-8173-4c9f-9c72-cbcabe953eb3-8ca299e2"
                sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 880px"
                srcset="./img/Group-664-p-500.png 500w, ./img/Group-664-p-800.png 800w, ./img/Group-664.png 880w"
                alt=""
              />
            </a>
            <a
              href="https://www.bostonglobe.com/2021/03/29/metro/these-founders-are-building-new-social-network-that-goes-back-basics/"
              target="_blank"
              class="w-inline-block"
            >
              <img
                src="./img/boston-globe-white-1.png"
                loading="lazy"
                sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 784px"
                srcset="./img/boston-globe-white-1-p-500.png 500w, ./img/boston-globe-white-1.png 772w"
                alt=""
                class="image-25"
              />
            </a>
            <a
              href="https://www.linkedin.com/posts/the-quest-media_backed-by-former-facebook-and-twitter-execs-activity-6878058049198813184-gniF/"
              target="_blank"
              class="w-inline-block"
            >
              <img
                src="./img/image-4.png"
                loading="lazy"
                height="200"
                alt=""
                class="image-24"
              />
            </a>
            <a
              href="https://sg.news.yahoo.com/backed-former-facebook-twitter-execs-155157864.html?guccounter=1"
              target="_blank"
              class="w-inline-block"
            >
              <img
                src="./img/yahoo.png"
                loading="lazy"
                id="w-node-_58904e7c-e4d8-cb27-5200-f665200d5fd3-8ca299e2"
                sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
                srcset="./img/yahoo-p-500.png 500w, ./img/yahoo-p-800.png 800w, ./img/yahoo-p-1080.png 1080w, ./img/yahoo.png 1500w"
                alt=""
                class="image-27"
              />
            </a>
            <a
              href="https://www.builtinsf.com/2022/01/25/san-francisco-future-5-startups-q1-2022"
              target="_blank"
              class="w-inline-block"
            >
              <img
                src="./img/built-in-sf.png"
                loading="lazy"
                id="w-node-_9365f712-36ee-aae9-f08f-4474efa0be82-8ca299e2"
                sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
                srcset="./img/built-in-sf-p-500.png 500w, ./img/built-in-sf-p-800.png 800w, ./img/built-in-sf-p-1080.png 1080w, ./img/built-in-sf-p-1600.png 1600w, ./img/built-in-sf-p-2000.png 2000w, ./img/built-in-sf.png 2208w"
                alt=""
                class="image-28"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="wf-section">
        <div class="w-container">
          <div class="text-block-12">Is something broken lol?</div>
          <div class="div-block-23-copy">
            <div class="text-block-15">
              <strong class="bold-text-5">Text us directly for support</strong>
            </div>
          </div>
          <div class="div-block-30">
            <a
              href="http://my.community.com/tagg"
              target="_blank"
              class="button w-button"
            >
              Contact Us
            </a>
          </div>
          <div class="div-block-17">
            <div class="w-form">
              <form
                id="email-form-2"
                name="email-form-2"
                data-name="Email Form 2"
                method="get"
                class="form"
              >
                <input
                  type="tel"
                  class="text-field-5 w-input"
                  maxlength="256"
                  name="name"
                  data-name="Name"
                  placeholder="Enter your phone number"
                  id="name"
                />
                <input
                  type="submit"
                  value="Subscribe"
                  data-wait="Please wait..."
                  class="button w-button"
                />
              </form>
              <div class="success-message-2 w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div class="w-form-fail">
                <div class="text-block-13">
                  Oops! Something went wrong while submitting the form.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-4 wf-section footer">
        <div class="container-2 w-container">
          <div class="div-block-10">
            <img
              src="./img/Written-small.png"
              loading="lazy"
              alt=""
              class="image-15"
            />
          </div>
          <div class="w-layout-grid grid-3">
            <a href="/terms-of-service" class="footer-link">
              Terms of Service
            </a>
            <a href="/privacy" class="footer-link">
              Privacy
            </a>
            <a href="http://my.community.com/tagg" class="footer-link">
              Contact Us
            </a>
            <a href="/community-guidelines" class="footer-link">
              Community Guidelines
            </a>
          </div>

          <div class="div-block-10 social-icons">
            <a
              id="w-node-_0420ce15-5395-d1fe-7d38-be056434b61b-8ca299e2"
              href="https://www.instagram.com/taggapp/?hl=en"
              target="_blank"
              class="w-inline-block"
            >
              <img src="./img/instagram.png" loading="lazy" alt="" />
            </a>

            <a
              id="w-node-_0420ce15-5395-d1fe-7d38-be056434b61d-8ca299e2"
              href="https://www.tiktok.com/@tagg_app"
              target="_blank"
              class="w-inline-block"
            >
              <img src="./img/tiktok.png" loading="lazy" alt="" />
            </a>

          </div>
        </div>
      </div>
    </div>
  );
}
