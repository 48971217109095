import {
  drawerWidth,
  transition,
  container,
} from "./material-dashboard-react";

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100%",
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
  },
  content: {
    // marginTop: "70px",
    // padding: "30px 15px",
    // minHeight: "calc(100vh - 123px)",
    overflow: 'hidden'
  },
  container: {

  },
  map: {
    marginTop: "70px",
  },
});

export default appStyle;
