import * as React from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import {Img} from 'react-image'
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import TierModal from '../modals/TierModal';
import Coin from '../../assets/img/Coinprofile.png'

const useStyles = makeStyles((theme) => ({
  userBox: {
    background: props => {
      if (props.template_type === "FOUR") {
        if (props.bio_color_start && props.bio_color_end) {
          return `linear-gradient(96.26deg, ${props.bio_color_start} 1.72%, ${props.bio_color_end} 102.48%)`  
        }
        return props.bio_color_start || `linear-gradient(96.26deg, ${props.primary_color || '#3D79C5'} 1.72%, ${props.secondary_color || '#FFF3F8'} 102.48%)`;
      } else {
        return props.secondary_color || "linear-gradient(95.66deg, #892101 1.74%, #5F1701 100%)";
      }
    },
    position: "relative"
  },
  userFullName: {
    marginBottom: props => {
      switch (props.template_type_type) {
        case "FOUR":
          return "0px";
        default: 
          return null;
      }
    },
    color: props => {
      if (props.template_type_type === "FOUR") {
          return props.bio_text_color || '#828282'
      }else if (props.template_type === "ONE") {
        return props.primary_color || '#828282'
      } else {
          return props.bio_text_color || '#828282'
      }
    },
    fontSize: 18, 
    fontWeight:'700'
  },

  userName: {
    color: props => {
    if (props.template_type === "ONE") {
      return props.primary_color || '#828282'
    }else{
      return props.bio_text_color || '#828282'
    }
      
    },
    margin: props => {
      if (props.template_type === "FOUR") {
        return "5px";
      } else {
        return "";
      }
    },
    fontSize: 13,
  },
  userInfoBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
  },
  outerBox: {
    margin:'0 10px 8px', 
    marginBottom:'0px'
  },
  tierBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    top: -8
  },
  bioText: {
    fontSize: 13,
    fontWeight: '500',
    marginBottom: 8,
    color: props => {
      if (props.template_type === "ONE") {
        return props.primary_color || 'white'
      }else{
        return props.bio_text_color || '#424242'
      }
    }
  },
  tagScore: {
    color: props => {
    if (props.template_type === "ONE") {
      return props.primary_color || 'white'
    }else{
      return props.bio_text_color || 'white'
    }
    },
    fontSize:"13px", 
    fontWeight:"700", 
    display:"flex", 
    alignItems:"center",
    fontFamily: 'SF Pro Text Bold',       
  }
}));

/**
 * Shows user's data including name, picture, tagg, tagg score, biography etc<br>
 * This is used for template 1 and template 4
 */
export default function UserBox({userData}) {
  const [tierOpen, setTierOpen] = React.useState(false);
  const classes = useStyles(userData.skin);
  return (
    <>
    <Grid className={classes.outerBox} md={12}>
      <Grid className={classes.userBox} sx={{ height:"185px", borderRadius: "10px" }} container spacing={0}>
        <Grid item sx={{display: 'flex'}} xs={6}>
          <Img src={userData.user.profile_pic} style={{margin:'auto', borderRadius: "10px", objectFit: "cover" }} 
          height="150px" width="130px" alt="Profile pic" 
          loader={<img style={{margin:'auto', backgroundColor:"#C4C4C4"}} height="150px" width="130px" src="/img/missing.png"  alt="Profile pic" /> }
          unloader={<img style={{margin:'auto', backgroundColor:"#C4C4C4"}} height="150px" width="130px" src="/img/missing.png" alt="Profile pic" /> } />
        </Grid>
        <Grid item sx={{justifyContent:'center', textAlign:'center', maxHeight: "164px", display:""}} xs={6}>
          <Box className={classes.userInfoBox}>
            <Typography className={classes.userFullName}>{userData.user.first_name} {userData.user.last_name}</Typography>
            <Box className={classes.tierBox}>
              <Typography className={classes.userName}>@{userData.user.username} </Typography>
              <IconButton onClick={() => setTierOpen(true)}>
                <img src={`/img/icon/tier${userData.user.tier}.png`} alt={`tier${userData.user.tier}`} 
                width={userData.user.tier === 1 ? 12 : 16} height={userData.user.tier === 1 ? 12 : 16} 
                style={{objectFit: 'contain'}} />
              </IconButton>
            </Box>
            
            {userData.skin.template_type === "FOUR" ? 
              <Typography className={classes.bioText}>{userData.user.biography ? userData.user.biography : null}</Typography>
              : null
            }
            {userData.skin.template_type === "ONE" ? 
              <Typography className={classes.tagScore}>
                <span style={{fontFamily: 'SF Pro Text Regular', paddingRight: '4px', fontWeight: '400'}}>Coins Earned:</span>
                {userData.tagg_score}
                <img src={Coin} style={{margin:"2px 0 0 2px"}} alt="Coin" width={20} height={20}/>
              </Typography>
             : null
            }
          </Box>
          {userData.skin.template_type === "FOUR" ? 
              <Typography className={classes.tagScore} style={{position:"absolute",  bottom: "8px", right: "12px"}}>
                <span style={{fontFamily: 'SF Pro Text Regular', paddingRight: '4px', fontWeight: '400'}}>Coins Earned:</span>
                {userData.tagg_score}
                <img src={Coin} style={{margin:"2px 0 0 2px"}} alt="Coin" width={20} height={20}/>
              </Typography>
             : null
            }
        </Grid>
      </Grid>
    </Grid>
    <TierModal open={tierOpen} onClose={() => setTierOpen(false)}
    tier={userData?.user?.tier} score={userData?.tagg_score} />
    </>
  );
}

UserBox.propTypes = {
  /** User's data including name, picture, tagg, biography */
  userData: PropTypes.object.isRequired
}
