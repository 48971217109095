import {Box, Button, IconButton, Modal, Typography} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Coin from '../../assets/img/Coinprofile.png';

const title = ['New kid on the block', 'Apprentice', 'Artisan', 'Specialist', 'Socialite'];
const bodyA = [
  'Show every and anything you want. The more you share, the faster you’ll level up!!!',
  "Now you're getting the hang of things! Your profile is gaining attention. Use insights to add the taggs to boost your pages!",
  "You've mastered branding now. Let's work on the social part! Connect with other creatives to level up!",
  "Your profile is truly a work of art! Time to dive deeper into your passions! Nothing here is off-brand so don't be shy✨",
  <span>You’re in the top 10% of all creatives on Tagg<img src='/img/icon/happy_star_eye.png'/>. With great power comes great responsibility! Collab and uplift other creatives!"</span>
]

const body = [
  <span> This creative just arrived! Introduce yourself and welcome them to this social branding community <img src='/img/icon/image51.png' style={{position: 'relative', top: 3}}/></span>,
  'Authenticity is the key to engagement! Check out this creative’s vibes! 👀.',
  'Exploring is essential as you may discover new trends and aesthetics. Explore this creative’s profile for inspo 🤔.',
  'Social branding = interest-based content sharing. We stan all parts of you. Check out the pages on this creative’s profile 😌.',
  'Creatives grow through collaboration! You will benefit from befriending this creative 😄.'
]

/**
 * A modal to describe tier
 */
export default function TierModal({open, onClose, tier, score}) {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box className={classes.root}>
        <Box className={classes.tier}>
          <img src={`/img/icon/tier${tier}.png`} 
          width={tier === 1 ? 48: 64} height={tier === 1 ? 48: 64}
          style={{objectFit: 'contain'}} />
        </Box>
        <Typography className={classes.title}>{title[tier-1]}</Typography>
        <Typography className={classes.body}>{body[tier-1]}</Typography>
        <Typography className={classes.tagg}>
          Tagg Coin: {score} <img src={Coin} style={{margin:"2px 0 0 3px"}} alt="Coin" width={20} height={20}/>
        </Typography>
        <Button className={classes.button} onClick={onClose}>
          Close
        </Button>
      </Box>
    </Modal>
  )
}

TierModal.propTypes = {
  /** Whether the modal is visible or not */
  open: PropTypes.bool,
  /** Function to call when the modal gets closed */
  onClose: PropTypes.func,
  /** Tier level */
  tier: PropTypes.number,
  /** Tagg score */
  score: PropTypes.number,
}

TierModal.defaultProps = {
  open: false,
  tier: 1,
  score: 0
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    maxWidth: '90vw',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: 24,
    padding: '24px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: "center"
  },
  tier: {
    width: 100,
    height: 100,
    backgroundColor: '#f2f2f2',
    borderRadius: 100,
    position: 'absolute',
    top: -43,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    marginTop: 57,
    color: 'black',
    fontSize: 20,
    fontWeight: '700'
  },
  body: {
    fontSize: 13,
    fontWeight: '600',
    marginTop: 8,
    color: '#4F4F4F',
    textAlign: 'center'
  },
  tagg: {
    color: '#698DD3',
    fontSize: 13,
    fontWeight: '600',
    marginTop: 24,
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    background: "#698DD3",
    borderRadius: 40,
    color: "white",
    width: "200px",
    margin: "24px 0px 8px 0px",
    fontSize: 13,
    fontWeight: '600',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#325bab'
    }
  }
}));