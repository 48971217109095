import React from "react";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import Nav from "../../components/Static/Nav";

import routes from "../../routes";

export default function TaggOfficeHours({ ...rest }) {
  return (
    <div>
      <Nav headerClass="transparent-header" activeMenu="tag-office-hours"/>
      <div className="section-6 wf-section">
        <div className="div-block-12">
          <div className="w-container">
            <h1 className="tagg-heading-1-gradient">Tagg Office Hours</h1>
          </div>
        </div>
      </div>
      <div className="wf-section">
        <div className="container-6-copy w-container">
          <div className="w-layout-grid grid-10">
            <div
              id="w-node-_199afce9-4099-f3fc-cbe6-bec8e9cd9b8b-e8daa3ea"
              className="div-block-29-copy"
            >
              <img
                src="./img/cosmo-illustrations-07.png"
                loading="lazy"
                sizes="120px"
                srcset="./img/cosmo-illustrations-07-p-500.png 500w, ./img/cosmo-illustrations-07-p-800.png 800w, ./img/cosmo-illustrations-07-p-1080.png 1080w, ./img/cosmo-illustrations-07.png 1500w"
                alt=""
                className="image-20"
              />
            </div>
            <div>
              <h2 className="heading-16">Syllabus</h2>
              <div className="text-block-14">
                **The only syllabus you will ever read**
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cms-blog wf-section">
        <div className="container-14 w-container">
          <h2 className="heading-16">Class Essentials </h2>
          <h3 className="heading-19">
            We will talk about the creative space, what it means to be a
            creative, and the foundations of "<em>social branding."</em>
          </h3>
          <div className="w-layout-grid grid-5">
            <a
              href="what-the-f-ck-is-social-branding.html"
              className="w-inline-block"
            >
              <div className="w-layout-grid grid-6">
                <img
                  src="./img/Blogging-designs.png"
                  loading="lazy"
                  alt=""
                  className="image-18"
                />
                <div className="div-block-13">
                  <div className="div-block-40">
                    <div className="text-block-11">Branding</div>
                    <img
                      src="./img/circle-icon.png"
                      loading="lazy"
                      alt=""
                      className="image-21"
                    />
                    <div className="text-block-11-copy">2 min read</div>
                  </div>
                  <h1 className="blog-title">
                    What the f*ck is social-branding?
                  </h1>
                </div>
              </div>
            </a>
            <a href="coming-soon.html" className="w-inline-block">
              <div className="w-layout-grid grid-6">
                <img
                  src="images/Frame-899.png"
                  loading="lazy"
                  alt=""
                  className="image-18"
                />
                <div
                  id="w-node-_95a3ee03-089d-b19f-c3a1-00eb82882650-e8daa3ea"
                  className="div-block-13"
                >
                  <div className="text-block-11">Taggs</div>
                  <h1 className="blog-title">
                    Coming Soon
                    <br />
                    â€
                  </h1>
                </div>
              </div>
            </a>
            <a href="coming-soon.html" className="w-inline-block">
              <div className="w-layout-grid grid-6">
                <img
                  src="./img/Frame-1056.png"
                  loading="lazy"
                  alt=""
                  className="image-18"
                />
                <div className="div-block-13">
                  <div className="text-block-11">Analytics</div>
                  <h1 className="blog-title">
                    Coming Soon
                    <br />
                    â€
                  </h1>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="w-container">
          <div className="collection-list-wrapper w-dyn-list">
            <div role="list" className="w-dyn-items w-row">
              <div role="listitem" className="w-dyn-item w-col w-col-4">
                <a
                  href="/the-creator-problem-make-1-000-followers-feel-like-1-000-000"
                  className="w-inline-block"
                >
                  <div className="w-layout-grid blog-grid">
                    <div
                      style={{ backgroundImage:'url("./img/blogcoversvaporwavefour.png")'}}
                      className="thumbnail-div"
                    ></div>
                    <div className="blog-title-div">
                      <div className="text-block-11-copy">Tagg</div>
                      <h1 className="blog-title">
                        The Creator Problem: Make 1,000 followers feel like
                        1,000,000
                      </h1>
                    </div>
                  </div>
                </a>
              </div>
              <div role="listitem" className="w-dyn-item w-col w-col-4">
                <a
                  href="/can-anyone-be-a-content-creator-7-tips-on-how-to-get-started"
                  className="w-inline-block"
                >
                  <div className="w-layout-grid blog-grid">
                    <div
                      style={{ backgroundImage:'url("./img/blogcoversvaporwave.png")' }}
                      className="thumbnail-div"
                    ></div>
                    <div className="blog-title-div">
                      <div className="text-block-11-copy">Tagg</div>
                      <h1 className="blog-title">
                        Can Anyone Be A Content Creator? 7 Tips On How To Get
                        Started
                      </h1>
                    </div>
                  </div>
                </a>
              </div>
              <div role="listitem" className="w-dyn-item w-col w-col-4">
                <a
                  href="/what-the-f-ck-is-social-branding"
                  className="w-inline-block"
                >
                  <div className="w-layout-grid blog-grid">
                    <div
                      style= {{ backgroundImage:'url("./img/untitleddesign.png")' }}
                      className="thumbnail-div"
                    ></div>
                    <div className="blog-title-div">
                      <div className="text-block-11-copy">Tagg</div>
                      <h1 className="blog-title">
                        What the F*ck is Social Branding?
                      </h1>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-7 wf-section"></div>
      <div className="section-10 wf-section">
        <div className="container-6-copy-copy w-container">
          <div className="w-layout-grid grid-4">
            <img
              src="./img/cosmo-in-space-06.png"
              loading="lazy"
              id="w-node-fae2b7d9-0c6a-a4aa-1517-028e205ea83d-e8daa3ea"
              sizes="(max-width: 479px) 140px, 142px"
              srcset="./img/cosmo-in-space-06-p-500.png 500w, ./img/cosmo-in-space-06-p-800.png 800w, ./img/cosmo-in-space-06-p-1080.png 1080w, ./img/cosmo-in-space-06.png 1501w"
              alt=""
              className="image-17"
            />
            <div>
              <h2 className="heading-16">Download Tagg</h2>
         
              <p class="paragraph-4"> Expand past content creation in your usual niche to content based on ALL your interests and passions! What are you waiting for? Aren’t you curious??👀</p>
             
            </div>
            <div id="w-node-_7cf8e78e-b360-2c8a-a172-30aa27f112a0-e8daa3ea">
              <a
                href="https://apps.apple.com/us/app/tagg-powered-by-taggid-inc/id1537853613"
                target="_blank"
                className="button w-button"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section-11-copy wf-section">
        <div className="w-container">
          <div className="text-block-12">Text us to stay updated</div>
          <div className="div-block-23">
            <div className="text-block-15">
              <strong className="bold-text-5">
                Let us spam you, respectfully
              </strong>
            </div>
            <img
              src="./img/9378-fuckboi.png"
              loading="lazy"
              width="30"
              sizes="(max-width: 479px) 100vw, 30px"
              srcset="./img/9378-fuckboi-p-500.png 500w, ./img/9378-fuckboi.png 540w"
              alt=""
            />
          </div>
          <div className="div-block-30">
            <a
              href="http://my.community.com/tagg"
              target="_blank"
              className="button w-button"
            >
              Subscribe
            </a>
          </div>
          <div className="div-block-17">
            <div className="w-form">
              <form
                id="email-form-2"
                name="email-form-2"
                data-name="Email Form 2"
                method="get"
                className="form"
              >
                <input
                  type="tel"
                  className="text-field-5 w-input"
                  maxlength="256"
                  name="name"
                  data-name="Name"
                  placeholder="Enter your phone number"
                  id="name"
                />
                <input
                  type="submit"
                  value="Subscribe"
                  data-wait="Please wait..."
                  className="button w-button"
                />
              </form>
              <div className="success-message-2 w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div className="text-block-13">
                  Oops! Something went wrong while submitting the form.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
