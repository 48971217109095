import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { React, useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import ProfileCard from './ProfileListingCard';
import { topProfilesService } from '../../../services';
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../../components/Loader"
/**
 * Shows top profile cards
 */
export default function TopProfiles() {
  const [topUsers, setTopUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  /**
   * Fetch top profiles
   */
  async function getTopProfiles() {
    try {
      setLoading(true);
      const data = await topProfilesService();
      /*
      data.sort(function(a,b) {
        if (a["thumbnail_url"] > b["thumbnail_url"]) {
          return -1;
        } else {
          return 1;
        }
      })
      */
      setTopUsers(data ?? []);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  
  useEffect(() => {
    getTopProfiles();
  }, [])

  // Renders list of top profiles
  return (
 
    <Switch>
      { loading ? <Loader /> : 
      <Container className={classes.container} >
        <Grid container spacing={4}>
          {topUsers.map((user, idx) => (
            <Grid item key={idx} xs={12} sm={6} md={3}>
              <ProfileCard user={user} key={idx}/>
            </Grid>
          ))}
        </Grid>
      </Container>
      }
    </Switch>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    py: '0 !important',  
    marginBottom: '60px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '60px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop:'60px'
    }
  }
}))