import * as React from 'react';
import { Box, Button, Hidden, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import {Img} from 'react-image'
import PropTypes from 'prop-types';

import UserBox2 from '../components/UserBox2';
import PagesBar from '../components/PagesBar';
import Taggs from '../components/Taggs';
import MobileBottomNav from '../Navigation/MobileBottomNav';
import ProfileNavbar from '../components/ProfileNavbar';
import TierModal from '../modals/TierModal';
import { APPSTORE_URL } from '../../services/config';
import {useDispatch, useSelector} from "react-redux";
import { copyToClipboard } from '../../services/utils';

import { feedbackAction} from '../../redux/actions';

/**
 * User's profile in template 5
 */
export default function Template5({userData, inBackground, ChangeSlide}) {
  const [isVideo, setVideo] = React.useState(false);
  const [tierOpen, setTierOpen] = React.useState(false);
  const classes = useStyles(userData?.skin);
  const dispatch = useDispatch();
  const onClickShare = () => {
    copyToClipboard(window.location.href);
    dispatch(feedbackAction.showSnack({message: 'Link copied to clipboard'}));
  }
  return (
    <Box className={classes.root}>
      {
        !inBackground && 
        <Hidden mdUp>
          <Box style={{position: 'relative', height: 44, width: '100%'}}>
            <ProfileNavbar />
          </Box>
        </Hidden>
      }
      <Box style={{padding: 10}}>
        <Typography className={classes.userFullName}>{userData.user.first_name} {userData.user.last_name}</Typography>
        <Box className={classes.tierBox}>
          <Typography className={classes.userName}>{userData.user.username}</Typography>
          <IconButton onClick={() => setTierOpen(true)}>
            <img src={`/img/icon/tier${userData.user.tier}.png`} alt={`tier${userData.user.tier}`} 
            width={userData.user.tier === 1 ? 12 : 16} height={userData.user.tier === 1 ? 12 : 16} 
            style={{objectFit: 'contain'}} />
          </IconButton>
        </Box>
      </Box>
      
  
      <Box style={{height:"192px"}}>
        {isVideo ? 
        <video width="100%" height="192px" controls>
          <source src="movie.mp4" type="video/mp4" />
          <source src="movie.ogg" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
        :
        <Img src={userData.user.header_pic} height="100%" width="100%" style={{objectFit:'cover'}}
          unloader={<img src={'/img/default_header.png'} height="100%" width="100%" alt="header"/>}
          loader={<img src={'/img/default_header.png'} height="100%" width="100%" alt="header"/>}/>
        }
      </Box>
     
      <UserBox2 userData={userData} />

      <Box style={{margin: "0px 8px 0px"}}>
          <Button className={classes.btnAddFriend}
                  onClick={onClickShare}>
                Share this profile
          </Button>
      </Box>

      <Hidden mdUp>
        <PagesBar userData={userData} active="Home" ChangeSlide={(idx) => ChangeSlide(idx)}/>
      </Hidden>
      <Taggs isHome={true} userData={userData} />
      {/* <Hidden mdUp>
        <MobileBottomNav />
      </Hidden> */}
      <TierModal open={tierOpen} onClose={() => setTierOpen(false)}
      tier={userData?.user?.tier} score={userData?.tagg_score} />
    </Box>
  )
}

Template5.propTypes = {
  /** User's data including name, skin, picture etc */
  userData: PropTypes.object.isRequired,
  /** Whether rendering as background or not, mainly to decide to show profile navbar */
  inBackground: PropTypes.bool
}

Template5.defaultProps = {
  inBackground: false
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  userFullName: {
    color: props => {
      return props.secondary_color || 'white'
    },
    fontSize: 22, 
    fontWeight: '700'
  },
  tierBox: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    top: -8,
    marginBottom: -8
  },
  userName: {
    color: props => {
      return props.secondary_color || 'white'
    },
    fontSize: 11, 
    fontWeight: '600',
  },
  btnAddFriend: {
    color: props => {
      return props.primary_color || 'black'
    },
    width: '100%',
    textTransform: 'capitalize',
    fontSize: 15,
    fontWeight: '700',
    background: props => {
      return props.secondary_color || 'white'
    },
    '&:hover': {
      backgroundColor: props => {
        return props.secondary_color || 'white'
      },
    }
  }
}));