import React from 'react';
import PropTypes from 'prop-types';
import { typeOfMedia } from '../../services/utils';
import MyVideo from './video';

/**
 * Shows media according to its type<br>
 * Mainly used in moments
 */
const MyMedia = ({src, className, isCurrent, index}) => {
  const [type, setType] = React.useState();
  React.useEffect(() => {
    setType(typeOfMedia(src));
  }, [src]);

  switch (type) {
    case "img":
      return <img src={src} className={className} />
    case "video":
      return <MyVideo src={src} className={className} isCurrent={isCurrent} index={index} />
    default:
      return null;
  }
}

MyMedia.propTypes = {
  /** source url */
  src: PropTypes.string.isRequired,
  /** CSS classnames */
  className: PropTypes.string,
  /** Whether it is currently visible or not */
  isCurrent: PropTypes.bool
}

MyMedia.defaultProps = {
  isCurrent: false
}

export default MyMedia;

export {
  MyVideo
};
