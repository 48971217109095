// Retrieves User's name, username, tagg score, bio, friend count, 
// Header img, profile img, list of categories, home taggs
import axios from 'axios';
import { API_URL, BASE_URL } from './config';

// Example endpoint
// https://app-prod3.tagg.id/api/web/profile/?username=pat144

const TAG_CLICK_COUNT_ENDPOINT = BASE_URL + "api/insights/taggs/";
const VIEWER_ID = "c1fb3489-d947-459d-abc5-6d5ee02ad945"; //Default web user


export const registerTaggClick = async (widgetID) => {
    var raw = JSON.stringify({
        "widget_id": widgetID,
        "viewer_id": VIEWER_ID
      });
      var requestOptions = {
        method: 'POST',
        body: raw,
        headers: {"Content-Type": "application/json"},
        redirect: 'follow'
      };
      
      fetch(TAG_CLICK_COUNT_ENDPOINT, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }