import * as React from 'react';
import { Box, Button, Grid, Hidden, IconButton, Typography } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { makeStyles } from "@material-ui/core/styles";
import {Img} from 'react-image'
import PropTypes from 'prop-types';

import PagesBar from '../components/PagesBar';
import Taggs from '../components/Taggs';
import MobileBottomNav from '../Navigation/MobileBottomNav';
import ProfileNavbar from '../components/ProfileNavbar';
import TierModal from '../modals/TierModal';
import { APPSTORE_URL } from '../../services/config';

import {useDispatch, useSelector} from "react-redux";
import { copyToClipboard } from '../../services/utils';

import { feedbackAction} from '../../redux/actions';
import Coin from '../../assets/img/Coinprofile.png';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  btnAddFriend: {
    color: props => {
      return props.primary_color || '#333333'
    },
    width: '100%',
    textTransform: 'capitalize',
    fontSize: 15,
    fontWeight: '700',
    background: props => {
      return props.secondary_color || 'white'
    },
    '&:hover': {
      backgroundColor: props => {
        return props.secondary_color || 'white'
      },
    }
  },
  taggScoreText: {
    fontSize: 15,
    fontWeight: '700',
    textAlign: 'center',
    display:"flex", 
    alignItems:"center", 
    justifyContent:"center",
    fontFamily:"SF Pro Text Bold",
    color: props => {
      return props.secondary_color || 'white'
    }
  },
  userBox: {
    position: "absolute", 
    bottom: "0px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    background: props => {
      return `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${props.primary_color || 'black'} 70%)`
    },
    width: "100%",
    paddingTop: '100px',
    paddingBottom: '20px',
    marginBottom: '-5px'
  },
  userFullName: {
    fontSize: 48,
    lineHeight: '48px',
    color: props => {
      return props.secondary_color || 'white'
    }
  },
  userName: {
    fontSize: 15,
    color: props => {
      return props.secondary_color || 'white'
    }
  },
  coinText: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '17px',
    textAlign: 'center',
    color: props => {
      return props.secondary_color || 'white'
    }
  }
}));

/**
 * User's profile in template 3
 */
export default function Template3({userData, inBackground, ChangeSlide}) {
  const [tierOpen, setTierOpen] = React.useState(false);
  const classes = useStyles(userData?.skin);
  const dispatch = useDispatch();
  const onClickShare = () => {
    copyToClipboard(window.location.href);
    dispatch(feedbackAction.showSnack({message: 'Link copied to clipboard'}));
  }
  return (
    <Box className={classes.root}>
      {
        !inBackground && 
        <Hidden mdUp>
          <ProfileNavbar />
        </Hidden>
      }

      <Grid spacing={0}>
        <Box style={{position: "relative", width: '100%'}}>
          <Box style={{background: userData?.skin.primary_color, height: "427px"}}>
            <Img src={userData?.user.profile_pic} height="427px" width="100%" style={{objectFit: 'cover'}}
              unloader={<img src={'/img/default_header.png'} height="427px" width="100%" alt="header"/>}
              loader={<img src={'/img/default_header.png'} height="427px" width="100%" alt="header"/>}/>
          </Box>
      
          <Box>
            <Box className={classes.userBox}>
              <Typography className={classes.userFullName}>{userData?.user.first_name} {userData?.user.last_name}</Typography>
              <Box style={{display: 'flex', alignItems: 'center'}}>
                <Typography className={classes.userName}>@{userData.user.username}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Grid container spacing={0} style={{marginTop: 8, display: "flex", alignItems: "center"}}>
          <Grid item xs={3} md={3} >
            <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <IconButton onClick={() => setTierOpen(true)}>
                <img src={`/img/icon/tier${userData.user.tier}.png`} alt={`tier${userData.user.tier}`} 
                width={userData.user.tier === 1 ? 12 : 16} height={userData.user.tier === 1 ? 12 : 16} 
                style={{objectFit: 'contain'}} />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={6} md={6} >
            <Button className={classes.btnAddFriend}
                  onClick={onClickShare}>
                Share this profile
            </Button>
          </Grid>
          <Grid item xs={3} md={3} >
            <Typography className={classes.taggScoreText}>
              {userData.tagg_score} <img src={Coin} style={{margin:"2px 0 0 2px"}} alt="Coin" width={20} height={20}/>
            </Typography>
            <Typography className={classes.coinText}>Coins Earned</Typography>
          </Grid>
        </Grid>

        <Hidden mdUp>
          <PagesBar userData={userData} active="Home" ChangeSlide={(idx) => ChangeSlide(idx)}/>
        </Hidden>
        <Taggs isHome={true} userData={userData} />
        {/* <Hidden mdUp>
          <MobileBottomNav />
        </Hidden> */}
      </Grid>
      <TierModal open={tierOpen} onClose={() => setTierOpen(false)}
        tier={userData?.user?.tier} score={userData?.tagg_score} />
    </Box>
  )
}

Template3.propTypes = {
  /** User's data including name, skin, picture etc */
  userData: PropTypes.object.isRequired,
  /** Whether rendering as background or not, mainly to decide to show profile navbar */
  inBackground: PropTypes.bool
}

Template3.defaultProps = {
  inBackground: false
}