/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import icon assets for profiles and discover from figma

// core components/views for Admin layout
import MomentsPage from "./components/Views/Moments/TopMoments"
import ProfilesPage from "./components/Views/TopProfiles/TopProfiles.js";


const dashboardRoutes = [
  {
    path: "/home/profiles",
    name: "Profiles",
    icon: "/img/icon/user1.png",
    component: ProfilesPage,
  },
  {
    path: "/home/moments",
    name: "Discover Moment",
    icon: "/img/icon/discover1.png",
    component: MomentsPage,
  },
];

export default dashboardRoutes;
