import { FEEDBACK_SET, FEEDBACK_HIDE, FEEDBACK_SET_EXPLORE } from "../actionTypes";

const DEFAULT_STATE = {
	open: false,
  message: '',
  variant: 'success',

	exploreOpen: false,
	exploreData: {}
}

export default (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FEEDBACK_SET: {
			let variant = action.payload.variant ?? 'success';
			return { ...state, ...action.payload, variant };
		}
		case FEEDBACK_HIDE: {
			return { ...state, open: false };
		}
		case FEEDBACK_SET_EXPLORE: {
			return { ...state, ...action.payload }
		}
			
		default: return state;
	}

//   return state;
}
