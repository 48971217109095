import * as React from 'react';
import { Chip } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory, NavLink } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';

// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

import { profileAction, momentAction } from '../../redux/actions';
import { loadPageContent } from '../../services';

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "./hidescrollbar.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "nowrap",
    listStyle: "none",
    margin: 0,
    overflow: "auto",
    padding: "16px 8px 8px",
  },
  chip: {
    border: props => {
      if (props.secondary_color) {
        return `2px solid ${props.secondary_color}`;
      }
      switch (props.template_type) {
        case "ONE" :
          return "2px solid #892101";
        case "TWO" :
          return "2px solid #698DD3";
        default :
          return "2px solid white";
      }

    },

    backgroundColor: 'transparent !important',
    color: props => {
      if (props.secondary_color) {
        return props.secondary_color;
      }
      switch (props.template_type) {
        case "ONE" :
          return "#892101";
        case "TWO" :
          return "#698DD3";
        default :
          return "white";
      }
    },
    cursor: 'pointer',
    fontWeight: "700"
  },
  activeChip: {
    color: props => {
        return props.primary_color || '#828282' ;
    },
    backgroundColor: props => {
      if (props.secondary_color) {
        return props.secondary_color;
      }
      switch (props.template_type) {
        case "ONE" :
          return "#892101";
        case "TWO" :
          return "#698DD3";
        default :
          return "white";
      }
    },
    cursor: 'pointer',
    fontWeight: "700"
  }
}));

/**
 * Page bar on mobile browser
 */
export default function PagesBar({userData, active, ChangeSlide}) {
  const classes = useStyles(userData?.skin);
  const username  = userData?.user?.username;
  const dispatch = useDispatch();
  const history = useHistory();
  const [moments, setMoments] = React.useState([]);

  async function getTaggsAndMoments() {
    if (active !== "Home") {
      try {
        const data = await loadPageContent(userData.user.username, active);
        setMoments(data?.moments);
      } catch (e) {
        console.log(e);
      } finally {
      }
    }
  }
  const ChangeSlidefn = (idx) => {
    ChangeSlide(idx);
  }

  React.useEffect(() => {
    getTaggsAndMoments();
  }, [active])
  /**
   * Navigate to the specified page
   * @param {*} page 
   */
  const handlePageChange = (page) => {
    dispatch(momentAction.setMomentsData({data: moments}));
  }
  //  carousel variable
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4
    }
  };
  // Carousel variable end 
  const validPages = userData.pages;
  return (
    <div  className={`swiper-no-swiping scrolling-menus${userData.skin.template_type == 'TWO' || userData.skin.template_type == 'THREE' || userData.skin.template_type == 'FIVE' ? ' menu_template_2' : ''}`}>
      
      <ScrollMenu >
      {validPages.map((page, idx) => {
        if (page === "__TaggUserHomePage__" ) {
          return (
            <NavLink onClick={() => handlePageChange("Home")} style={{textDecoration:"none"}} key={idx} to={"/profiles/"+username} >
              <Chip className={active === 'Home' ? classes.activeChip : classes.chip} label="Home" />
            </NavLink>
          )
        } else {
          return (
            <NavLink onClick={() => handlePageChange(page) } style={{textDecoration:"none"}} key={idx} to={"/moments/"+username+"/"+page}>
              <Chip className={active === page ? classes.activeChip : classes.chip} label={page} />
            </NavLink>
          ) 
        }
        
      })}
      </ScrollMenu>
    </div>
  );
}

PagesBar.propTypes = {
  /** User's data including username, skin and pages */
  userData: PropTypes.object,
  /** The name of active page */
  active: PropTypes.string
}

PagesBar.defaultProps = {
  active: "Home"
}