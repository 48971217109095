import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { makeStyles } from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import ActionModal from '../modals/ActionModal';
import { copyToClipboard } from '../../services/utils';
import { feedbackAction } from '../../redux/actions';

/**
 * Top navigation bar in profile on mobile browser
 */
export default function ProfileNavbar() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const handleBack = () => {
    history.push("/")
  }

  /**
   * Handles user's action to share profile
   * @param {*} key 
   */
  const handleAction = (key) => {
    switch (key) {
      case 'share':
        copyToClipboard(window.location.href);
        dispatch(feedbackAction.showSnack({message: 'Link copied to clipboard'}));
        break;
    }

    setModalOpen(false);
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <IconButton className={classes.buttonRight} onClick={() => setModalOpen(true)}>
          <MoreHorizIcon className={classes.icon} />
        </IconButton>
      </Box>
    <ActionModal open={modalOpen} onClose={() => setModalOpen(false) } 
    actions={[{key: 'share', title: 'Share Profile'}]} 
    handleAction={handleAction} />
    </Box>
  )
}

ProfileNavbar.propTypes = {
  
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonLeft: {
    color: 'white',
    filter: 'drop-shadow(-1px 2px 4px rgba(0, 0, 0, 0.4))',
  },
  buttonRight: {
    marginLeft: 'auto',
    color: 'white',
    filter: 'drop-shadow(-1px 2px 4px rgba(0, 0, 0, 0.4))',
  },
  icon: {
    fontSize: 26,
  }
}));