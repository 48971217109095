import {useState} from 'react'
import '../../assets/css/normalize.css';
import '../../assets/css/webflow.css';
import '../../assets/css/tagg-e7e546.webflow.css';

export default function Nav({headerClass, activeMenu=''}) {
  const [toggle, setToggle] = useState('0')
  return (
    
    <div
      data-animation="default"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
       className={`navbar-4 w-nav ${headerClass ? headerClass : ''}`}
    >
      <div  className="w-container">
        <a href="/"  className="w-nav-brand">
        <img
            src="./img/cosmo.png"
            loading="lazy"
            width="50"
            sizes="50px"
            srcset="./img/cosmo-500.png 500w, ./img/cosmo-800.png 800w, ./img/cosmo-1080.png 1080w, ./img/cosmo-1600.png 1600w, ./img/cosmo-2000.png 2000w, ./img/cosmo.png 3261w"
            alt=""
            class="image"
          />
          
        </a>
        <nav role="navigation"  className={`nav-menu-2 w-nav-menu ${toggle === '0' ? 'closed' :  'opened' }`}>
          <a href="./get-tagged-in"  className="tagged-in-color w-nav-link">
            Get Tagged In
          </a>
          <a href="./tagg-office-hours" className={`nav-color w-nav-link ${activeMenu ==='tag-office-hours' ? 'w--current' : ''}`}>
            Tagg Office Hours
          </a>
          <a
            href="careers"
            aria-current="page"
             className={`nav-color w-nav-link ${activeMenu ==='carrers' ? 'w--current' : ''}`}
          >
            Careers
          </a>
        </nav>
        <div className={`menu-button-2 w-nav-button ${toggle === '0' ? 'hamburger' :  'cross' }`}
         onClick={(e) => { e.preventDefault(); toggle === '0' ? setToggle('1') : setToggle('0')}}>
          <div  className="icon-5-copy w-icon-nav-menu"></div>
        </div>
      </div>
    </div>
  );
}
