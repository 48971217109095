import axios from 'axios';
import { API_URL } from './config';
export async function generateLinkData(url,link_type) {
    let linkData = {
        images: [''],
        title: '',
      };
    if (url === undefined){
        return linkData;
    } 
  if (url !== undefined && !(url.startsWith('https://') || url.startsWith('http://'))) {
    url = 'https://' + url;
  }
 
  try {
    if (
      link_type === 'YOUTUBE' ||
      link_type === 'YOUTUBE_MUSIC'
    ) {
      let data;
      if (
        url.match(
          /^(http|https)?:\/\/(?:www\.)?youtube\.com(?:.*|\/)\?(?=.*=((\w|-){3}))(?:\S+)?$/i,
        )
      ) {
        data = await fetch(`https://noembed.com/embed?url=${url}`).then(data => data.json());
      } else if (url.includes('channel') || url.includes('c') || url.includes('user')) {
        data = await fetch(`https://noembed.com/embed?url=${url}`).then(data => data.json());
        if (!data.title) {
          data.title = 'Youtube';
          data.thumbnail_url = '';
        }
      } else {
        const uri = url.replace('youtu', 'youtube').replace('.be/', '.com/watch?v=');
        data = await fetch(`https://noembed.com/embed?url=${uri}`).then(data => data.json());
      }

      linkData = {
        images: [data.thumbnail_url],
        title: data.title,
      };
    } else {
       /* try {
            const response = await fetch('/movies', { 
              signal: controller.signal 
            });
          } catch (error) {
            console.log('Fetch error: ', error);
          } */

          await axios({
            method: "get",
            url: API_URL + "retrieve_image/?url=" + url,
            timeout: 1000 * 20, // Wait for 5 seconds
            headers: {
              "Content-Type": "application/json"
            }
          })
            .then(response => {
              const serverResponse = response.data;
              if('title' in serverResponse){
                linkData = {
                    images: Array.isArray(serverResponse.image) === true?serverResponse.image[0] : serverResponse.image,
                    title: serverResponse.title[0],
                  };
              }
              
            })
            .catch(error => {
            //  console.log(error);
          });
     
    }

    if (link_type === 'EMAIL' && url.includes('@')) {
      linkData.images = [''];
    }

    return linkData;
  } catch (e) {
    return linkData;
  }
}
