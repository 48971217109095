import { API_URL } from "./config";

// Example endpoint
// https://app-prod3.tagg.id/api/web/top_profiles

const TOP_PROFILES_ENDPOINT = API_URL+"top_profiles";

export const topProfilesService = async () => {
  try {
    const response = await fetch(TOP_PROFILES_ENDPOINT , {
      method: 'GET',
    });
    const status = response.status;
    if (status === 200) {
      const data = await response.json();
      return data;
    } else {
      alert('Unable to load profile data');
    }
  } catch (error) {
    alert("Something went wrong. Please refresh the page");
  }
};