import * as React from 'react';
import { Box, Grid} from '@mui/material';
import { Button, IconButton, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import {Img} from 'react-image'
import PropTypes from 'prop-types';

import PagesBar from '../components/PagesBar';
import Taggs from '../components/Taggs';
import MobileBottomNav from '../Navigation/MobileBottomNav';
import TierModal from "../modals/TierModal";
import ProfileNavbar from '../components/ProfileNavbar';
import { APPSTORE_URL } from '../../services/config';

import {useDispatch, useSelector} from "react-redux";
import { copyToClipboard } from '../../services/utils';

import { feedbackAction} from '../../redux/actions';
import Coin from '../../assets/img/Coinprofile.png';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  btnAddFriend: {
    color: props => {
      return props.primary_color || 'white'
    },
    width: '100%',
    textTransform: 'capitalize',
    fontSize: 15,
    fontWeight: '700',
    background: props => {
      return props.secondary_color || '#698DD3'
    },
    '&:hover': {
      backgroundColor: props => {
        return props.secondary_color || '#698DD3'
      },
    }
  },
  cutBox: {
    height:"80px",
    position: "absolute", 
    bottom: "0px",
    width: "100%",
    WebkitTransform: "skew(-60deg)",
    marginLeft:"70px",
    textAlign: 'center',
    justifyContent: 'center',
    color: 'black',
    background: props => {
      return props.primary_color || 'white'
    }
  },
  tierBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bioText: {
    fontSize: 13,
    fontWeight: '600',
    marginBottom: 8,
    color: props => {
      return props.bio_text_color || 'black'
    }
  },
  userName: {
    color: props => {
      return props.bio_text_color || '#828282'
    },
    fontSize: 13,
    fontWeight: '600'
  }
}));

/**
 * User's profile in template 2
 */
export default function Template2({userData, inBackground, ChangeSlide}) {
  const [tierOpen, setTierOpen] = React.useState(false);
  const classes = useStyles(userData?.skin);
  const dispatch = useDispatch();
  const onClickShare = () => {
    copyToClipboard(window.location.href);
    dispatch(feedbackAction.showSnack({message: 'Link copied to clipboard'}));
  }

  return (
    <Box className={classes.root}>
      {
        !inBackground && 
        <Hidden mdUp>
          <ProfileNavbar />
        </Hidden>
      }

      <Grid sx={{height:"288px", position: "relative"}} container>
        <Grid item xs={12} md={12} sx={{height:"288px", background: userData.skin.primary_color, width:"357px"}}>
          <Img src={userData.user.header_pic} height="288px" width="100%" 
            unloader={<img src={'/img/default_header.png'} height="288px" width="100%" alt="header"/>}
            loader={<img src={'/img/default_header.png'} height="288px" width="100%" alt="header"/>}/>
        </Grid>
    
        <Grid sx={{ background: "transparent"}} item xs={12} md={12}>
          <Box className={classes.cutBox} />

          <Grid container sx={{height: "80px" ,position:"absolute", bottom:"0px"}}>
            <Grid item xs={4} md={4} sx={{maxHeight: "80px"}}>
            <Img src={userData.user.profile_pic} style={{borderRadius:"50px", display:"block", margin:"auto"}} 
            height="84px" width="84px" alt="Profile pic" 
            loader={<img style={{borderRadius:"50px", display:"block", margin:"auto", backgroundColor:"#C4C4C4"}} height="84px" width="84px" src="/img/missing.png" alt="ProfilePic" /> }
            unloader={<img style={{borderRadius:"50px", display:"block", margin:"auto", backgroundColor:"#C4C4C4"}} height="84px" width="84px" src="/img/missing.png" alt="ProfilePic" /> } />
            </Grid>
            <Grid item xs={8} md={8} sx={{maxHeight: "80px"}}>
              <div>
                <p style={{fontSize:"17px", fontWeight: "700", margin:"5px", textAlign:"center"}}>{userData.user.first_name} {userData.user.last_name}</p>
                <Box className={classes.tierBox}>
                  <Typography className={classes.userName}>@{userData.user.username}</Typography>
                  <IconButton onClick={() => setTierOpen(true)} style={{padding:"0 0 0 5px"}}>
                    <img src={`/img/icon/tier${userData.user.tier}.png`} alt={`tier${userData.user.tier}`} 
                    width={userData.user.tier === 1 ? 12 : 16} height={userData.user.tier === 1 ? 12 : 16} 
                    style={{objectFit: 'contain', padding:"0"}} />
                  </IconButton>
                </Box>
                  
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box style={{margin: "8px"}}>
          <Box style={{fontSize:"13px", textAlign:"left", width:"100%", display:"flex", marginBottom:"4px", fontFamily: 'SF Pro Text Bold'}} >
            <p style={{margin:"0 0 0 0px", fontWeight:"600", display:"flex", alignItems:"center"}}>
              <span style={{fontFamily: 'SF Pro Text Regular', paddingRight: '4px', fontWeight: '400'}}>Coins Earned:</span>
              {userData.tagg_score}<img src={Coin} style={{margin:"0 0 0 2px"}} alt="Coin" width={18} height={18}/>
            </p>
          </Box>
        <Typography className={classes.bioText}>{userData.user.biography ? userData.user.biography : null}</Typography>
      </Box>

      <Box style={{margin: '8px 8px 0px'}}>
          <Button className={classes.btnAddFriend} 
                  onClick={onClickShare}>
            Share this profile
          </Button>
      </Box>

      <Hidden mdUp>
        <PagesBar userData={userData} active="Home" ChangeSlide={(idx) => ChangeSlide(idx)}/>
      </Hidden>
      <Taggs isHome={true} userData={userData} />
      {/* <Hidden mdUp>
        <MobileBottomNav />
      </Hidden> */}
      <TierModal open={tierOpen} onClose={() => setTierOpen(false)}
      tier={userData?.user?.tier} score={userData?.tagg_score} />
    </Box>
  )
}

Template2.propTypes = {
  /** User's data including name, skin, picture etc */
  userData: PropTypes.object.isRequired,
  /** Whether rendering as background or not, mainly to decide to show profile navbar */
  inBackground: PropTypes.bool
}

Template2.defaultProps = {
  inBackground: false
}