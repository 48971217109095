import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types'

/**
 * Component to preview moment
 */
export default function MomentPreview(props) {
  return (
    <Box
      sx={{
        width: '115px',
        height: '115px',
        backgroundColor: 'primary.dark',
        '&:hover': {
          backgroundColor: 'primary.main',
          opacity: [0.9, 0.8, 0.7],
        },
        textAlign:'center',
        justifyContent:'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '0px',
        marginTop: '10px !important'
      }}
    >
      <img src={props.thumbNail} alt="Moment" style={{maxWidth:'115px', maxHeight:"115px"}}></img>
    </Box>   
  );
}

MomentPreview.propTypes = {
  /** Url of thumbnail */
  thumbNail: PropTypes.string
}