import * as React from 'react';
import { useEffect} from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import {Img} from 'react-image'
import { WidgetSubType, WidgetType } from '../../services/config';
import { convertHexToRGBA, validateWidgetThumbnailUrl } from '../../services/utils';
import { registerTaggClick } from '../../services/CountService';
import { isExternalModuleNameRelative } from 'typescript';
import { individualTaggCount } from '../../services/UserProfileService'
import { ContactSupportOutlined } from '@material-ui/icons';
import tagClickIcon from '../../assets/img/tagclick.png';
import defaultRecenetImg from '../../assets/img/Widgets/default_recent_moments.png';
import clsx from 'clsx';
import { generateLinkData } from '../../services/UrlPreview'



/**
 * Widget on profile page
 */
export default function Tagg({userData, isHome, widget, individualTaggdata}) {
  const dataObj = {
    ...userData.skin,
    isHome
  }

  const [TagInsight, setTagInsight] = React.useState([]);
  const [individualTaggAPIHit, setIndividualTaggAPIHit] = React.useState();
  useEffect(() => {
    setIndividualTaggAPIHit(false)
  }, []);

  // Individual tagg count
   useEffect(() => {
      //getindvidualtagclick()
      try {
        const data = individualTaggdata;
          var index = data.individual.map((o) => o.widget_id).indexOf(widget.id);
          if(index >= 0){
            setTagInsight(data.individual[index])
          }
          individualTaggAPIHit(true)
      } catch (e) {
       // console.log(e);
      } finally {
      }
  }, [individualTaggdata])
  const classes = useStyles();
  const [uiData, setUIData] = React.useState({});
  const [isGradient, setGradient] = React.useState(false);

  React.useEffect(() => {
    fetchPreviewData();
  }, []);
  // extract data including username and background on initial loading
  const fetchPreviewData = async () => {
    
    if(widget.url && widget.url != ''){
     // if(widget.url.includes('tiktok')===false){
        await generateLinkData(widget.url, widget.link_type).then((res) => {
              widget.url_title= res.title;
              widget.url_image= res.images;
          
          });
      //}
    }
    let gradient = false;
    let solidCustom = false;
    let gradientString = "";
    let gradientString2 = '';
    if ((widget?.border_color_start != null && widget?.border_color_end != null) ||
      (widget.background_color_start != null && widget.background_color_end != null)) {
      gradient = true;
      gradientString = `linear-gradient(0deg, ${widget.border_color_start || widget.background_color_start} 0%, ${widget.border_color_end || widget.background_color_end} 100%)`;
      gradientString2 = `linear-gradient(0deg, ${convertHexToRGBA(widget.border_color_start || widget.background_color_start, 100)} 0%, ${convertHexToRGBA(widget.border_color_end || widget.background_color_end, 100)} 100%)`;
      setGradient(true);
    } else if (widget.border_color_start != null || widget.background_color_start != null) {
      solidCustom = true;
    }

    let newUIData = {};
    
    if (widget.type === WidgetType.VIDEO_LINK || widget.type === WidgetType.GENERIC_LINK) {
      try {
        if (widget.link_type !== "EMAIL") {
          let url_obj = new URL(widget.url ?? '');
          let url_segments = url_obj.pathname.split("/");
        }
        newUIData.username = widget.title || defaultNames[widget.link_type];
      } catch (e) {
        console.log(e);
        newUIData.username = defaultNames[widget.link_type];
      } 
      const validImage = validateWidgetThumbnailUrl(widget.thumbnail_url);
      newUIData.background = gradient ? gradientString : solidCustom ? (widget.border_color_start || widget.background_color_start) : null;
      newUIData.image = validImage === false ? bgImages[widget.type][widget.link_type] : widget.thumbnail_url;
      newUIData.icon = validImage === true ? Icons[widget.type][widget.link_type] : null;
      newUIData.background_url = widget.background_url?widget.background_url:null;
    } else if (widget.type === WidgetType.APPLICATION_LINK) {
      try {
        let url_obj = new URL(widget.url ?? '');
        let url_segments = url_obj.pathname.split("/");
        newUIData.username = widget.title || defaultNames[widget.link_type];
      } catch (e) {
        newUIData.username = defaultNames[widget.link_type];
      } 
      const validImage = validateWidgetThumbnailUrl(widget.thumbnail_url);
      newUIData.background = gradient ? gradientString2 : solidCustom ? (widget.border_color_start || widget.background_color_start) : null;
      newUIData.image = validImage === false ? bgImages[widget.type][widget.link_type] : widget.thumbnail_url;
      newUIData.icon = validImage === true ? Icons[widget.type][widget.link_type] : null;
      newUIData.background_url = widget.background_url?widget.background_url:null;
      
    } else if (widget.type === WidgetType.SOCIAL_MEDIA) {
      const validImage = validateWidgetThumbnailUrl(widget.thumbnail_url);
      newUIData = {
        username: !!widget.username ? '@'+widget.username : defaultNames[widget.link_type],
        background: gradient ? gradientString2 : solidCustom ? (widget.border_color_start || widget.background_color_start) : null,
        background_url: widget.background_url ? widget.background_url:null
      }
      newUIData.image = validImage === false ? bgImages.SOCIAL_MEDIA[widget.link_type] : widget.thumbnail_url;
      newUIData.icon = validImage === true ? Icons[widget.type][widget.link_type] : null;
    }
    setUIData(newUIData);
  }

  // navigate to corresponding url when user clicks this widget
  const handleClick = () => {
    registerTaggClick(widget.id)
    if (!!widget.url) {
      if (widget.link_type === "EMAIL") {
        window.open('mailto:'+widget?.url, "_blank")
      } else {
        window.open(widget.url, "_blank")
      }
    } else if (widget.type === WidgetType.SOCIAL_MEDIA) {
      let url = `${socialHosts[widget.link_type]}/${widget.username}`;
      if ([WidgetSubType.SOCIAL_MEDIA.TIKTOK, WidgetSubType.SOCIAL_MEDIA.SNAPCHAT].includes(widget.link_type)) {
        url = `${socialHosts[widget.link_type]}/@${widget.username}`;
      }
      window.open(url, "_blank")
    } else if (!!widget.moment?.moment_url) {
      window.open(widget.moment?.moment_url, "_blank")
    }
  }
  const excludedType = [null, undefined, ''];

const url_image = widget.url_image? widget.url_image : '';
if(widget.background_color_start || widget.background_color_end) uiData.background_url = null;
  // render depending on widget type
  switch (widget.type) {
    case WidgetType.VIDEO_LINK:
      return (
        <Box onClick={handleClick}
        className={classes.box}
        style={{background: uiData.background}}
        >
          {
            (!excludedType.includes(uiData.background_url) || (!widget.border_color_start && !widget.border_color_end)) &&
            <div style={{backgroundImage: `url(${ !excludedType.includes(uiData.background_url)? uiData.background_url : uiData.image})`}} className={!excludedType.includes(uiData.background_url)?classes.backgroundImg : classes.blurBG} blurRadius={20} />
          }          
          {/* <div style={{backgroundImage: `url(${uiData.image})`}} className={classes.blurBG} blurRadius={20} /> */}
          <Box className={classes.innerBox}>
          <Box className={classes.innerWrapper}>
            <Box className={classes.imgWrapper}>
              {uiData?.icon && <img src={uiData.icon} className={classes.icons} />}
              {TagInsight && TagInsight.views > 0 ? (<div className={classes.tagClickCount}>
                <img src={tagClickIcon} alt=""/> 
                {TagInsight.views} 
              </div>) : null}
              <img className={classes.img} src={url_image!=''?url_image:uiData.image} alt="" />
              {/* <Img src={widget.thumbnail_url} className={classes.img} alt="" 
              loader={<img className={classes.img} src={bgImages.VIDEO_LINK[widget.link_type]} alt="" /> }
              unloader={<img className={classes.img} src={bgImages.VIDEO_LINK[widget.link_type]} alt="" /> } /> */}
            </Box>
            <Typography className={classes.username} style={{color: widget.font_color}}>{uiData.username}</Typography>
          </Box>
        </Box>   
        </Box>   
      );
    case WidgetType.GENERIC_LINK:
      return (
        <Box onClick={handleClick}
        className={classes.box}
        style={{background: uiData.background}}
        >
          {
            (!excludedType.includes(uiData.background_url) || (!widget.border_color_start && !widget.border_color_end)) &&
            <div style={{backgroundImage: `url(${ !excludedType.includes(uiData.background_url)? uiData.background_url : uiData.image})`}} className={ !excludedType.includes(uiData.background_url)?classes.backgroundImg : classes.blurBG} blurRadius={20} />
          }
          
          {/* <div style={{backgroundImage: `url(${uiData.image})`}} className={classes.blurBG} blurRadius={20} /> */}
          <Box className={classes.innerBox}>
          <Box className={classes.innerWrapper}>
            <Box className={classes.imgWrapper}>
              {uiData?.icon && <img src={uiData.icon} className={classes.icons} />}
              {TagInsight && TagInsight.views > 0 ? (<div className={classes.tagClickCount}>
                <img src={tagClickIcon} alt=""/> 
                {TagInsight.views} 
              </div>) : null}
              <img src={url_image!=''?url_image:uiData.image} className={classes.img} alt="img" />
            </Box>
            <Typography className={classes.username} style={{color: widget.font_color}}>{uiData.username}</Typography>
          </Box>
        </Box>   
        </Box>   
      );
    case WidgetType.APPLICATION_LINK:
      return (
        <Box onClick={handleClick}
        className={classes.box}
        style={{background: uiData.background}}
        >
          {
            (!excludedType.includes(uiData.background_url) || (!widget.background_color_start && !widget.background_color_end)) &&
            <div style={{backgroundImage: `url(${ !excludedType.includes(uiData.background_url)? uiData.background_url : uiData.image})`}} className={ !excludedType.includes(uiData.background_url) ? classes.backgroundImg : classes.blurBG} blurRadius={20} />
          }
          {/* <div style={{backgroundImage: `url(${uiData.image})`}} className={classes.blurBG} blurRadius={20} /> */}
          <Box className={classes.innerBox}>
          <Box className={classes.innerWrapper}>
            <Box className={classes.imgWrapper}>
              {uiData?.icon && <img src={uiData.icon} className={classes.icons} />}
              {TagInsight?.views > 0 ? (<div className={classes.tagClickCount}>
                <img src={tagClickIcon} alt=""/> 
                {TagInsight?.views} 
              </div>) : null}
              <img className={classes.img} src={url_image!=''?url_image:uiData.image} alt="" />
            </Box>
            <Typography className={classes.username} style={{color: widget.font_color}}>{uiData.username}</Typography>
          </Box>
        </Box>   
        </Box>   
      );
    case WidgetType.RECENT_MOMENT:
      return (
        <Box onClick={handleClick}
        className={clsx(classes.box, classes.recentBox)} 
        style={{backgroundImage: `url(${widget.moment?.thumbnail_url ? widget.moment?.thumbnail_url : defaultRecenetImg})`}}
        >
          <Box className={classes.innerBox}>
          <Box className={classes.innerWrapper}>
            <Box className={classes.momentTextWrapper}>
              {TagInsight?.views > 0 ? (<div className={classes.tagClickCount}>
                <img src={tagClickIcon} alt=""/> 
                {TagInsight?.views} 
              </div>) : null}
              <Typography style={{color: dataObj.bio_text_color}} className={classes.momentTitle}>{widget.page === '__TaggUserHomePage__' ? 'Home' : widget.page}</Typography>
              <Typography className={classes.momentText} style={{color: widget.font_color}}>Recent Moment</Typography>
            </Box>
          </Box>
        </Box>
        </Box>
      );
    case WidgetType.SOCIAL_MEDIA:
      if(widget.background_color_start || widget.background_color_end) uiData.background_url = null;
      return (
        <Box onClick={handleClick}
        className={classes.box}
        style={{background: uiData.background}}
        >
          {
            (!excludedType.includes(uiData.background_url) || (!widget.background_color_start && !widget.background_color_end))  &&
            <div style={{backgroundImage: `url(${ !excludedType.includes(uiData.background_url)? uiData.background_url : bgImages.SOCIAL_MEDIA[widget.link_type]})`}} className={ !excludedType.includes(uiData.background_url) ? classes.backgroundImg : classes.blurBG} blurRadius={20} />
          }
          <Box className={classes.innerBox}>
          <Box className={classes.innerWrapper}>
            <Box className={clsx (classes.imgWrapper, widget.link_type === 'FACEBOOK' ? classes.fbOverlay : null)}>
              {uiData?.icon && <img src={uiData.icon} className={classes.icons} />}
              {TagInsight && TagInsight.views > 0 ? (<div className={classes.tagClickCount}>
                <img src={tagClickIcon} alt=""/> 
                {TagInsight.views} 
              </div>) : null}
              <img className={classes.img} src={url_image!=''?url_image:uiData.image} alt="" />
            </Box>
            <Typography className={classes.username} style={{ color: widget.font_color}}>{widget.title ?? uiData.username}</Typography>
          </Box>
        </Box>   
        </Box>   
      );
    default:
      return (
        <Box className={classes.box} onClick={handleClick}
        style={{backgroundImage: `url(${widget.thumbnail_url})`}}
        >
        </Box>
      )
  }
}

Tagg.propTypes = {
  /** User's data including skin */
  userData: PropTypes.object.isRequired,
  /** Widget data including type, url etc */
  widget: PropTypes.object.isRequired
}



const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 10,
    // backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      // backgroundColor: theme.palette.primary.main,
      opacity: [0.9, 0.8, 0.7],
    },
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  innerBox: {
    position: 'relative',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: "column",
  },
  recentBox: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 100%)',
      backgroundSize: 'cover',
      zIndex: 0,
    }
  },
  username: {
    marginTop: '4px',
    width: '100%',
    overflow: 'hidden',
    textAlign: 'center',
    lineHeight: '18px',
    marginBottom: props => {
      if (props.isHome) {
        return "2px";
      }
    },
    fontSize: props => {
      if (props.template_type === "FOUR" && props.isHome) {
        return "5px";
      } else {
        return "13px";
      }
    },
    fontWeight: '700',
    fontFamily: 'SF Pro Text Bold',
    letterSpacing: '0em',
    wordBreak: 'break-word'
  },
  innerWrapper:{
    position:'relative',
      width:'100%',
      height:'100%',
      display:'flex',
      justifyContent:'flex-start',
      alignItems:'center',
      flexFlow:'column',
      padding:'15px 26px',
  },
  imgWrapper: {
    borderRadius: '8px',
    filter:'drop-shadow(-4px 4px 4px rgba(0, 0, 0, 0.25));',
    overflow: 'hidden',
    width: '120px',
    height: '120px',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.45) 100%)',
      backgroundSize: 'cover'
    }
  },
  fbOverlay: {
    '&::after': {
      display:'none'
    }
  },
  
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  icons: {
    position: 'absolute',
    left: '6px',
    top: '6px',
  },
  momentTextWrapper: {
    width: '100%',
    marginTop: 'auto',
    padding: '8px 7px'
  },
  momentTitle: {
    fontSize: 15,
    fontWeight: '600',
    color: 'white',
    position: 'relative',
    zIndex: 1,
  },
  momentText: {
    fontSize: 15,
    fontWeight: '500',
    color: '#C4C4C4',
    marginTop: 3,
    position: 'relative',
    zIndex: 1,
  },
  blurBG: {
    height: '100%',
    width: '600%',
    marginBottom: 24,
    position: 'absolute',
    zIndex: 0,
    filter: "blur(20px)",
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  backgroundImg:{
    width: '600%',
    height: '100%',
    zIndex: 0,
    position: 'absolute',
    marginBottom: 24,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  tagClickCount: {
    position: 'absolute',
    right: '6px',
    bottom: '6px',
    color: 'white',
    display:'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: '600',
    zIndex: '8'
  }
}));


const bgImages = {
  VIDEO_LINK: {
    YOUTUBE: "/img/widgets/youtube.png",
    TIKTOK: "/img/widgets/tik_tok.png",
    TWITCH: "/img/widgets/twitch.png",
    VIMEO: "/img/widgets/vimeo.png",
  },
  APPLICATION_LINK: {
    SPOTIFY: "/img/widgets/spotify.png",
    SOUNDCLOUD: "/img/widgets/soundcloud.png",
    APPLE_MUSIC: "/img/widgets/apple_music.png",
    APPLE_PODCAST: "/img/widgets/apple_podcast.png",
    POSHMARK: "/img/widgets/poshmark.png",
    DEPOP: "/img/widgets/depop.png",
    ETSY: "/img/widgets/etsy.png",
    SHOPIFY: "/img/widgets/shopify.png",
    AMAZON: "/img/widgets/amazon.png",
    AMAZON_AFFILIATE: "/img/widgets/amazon.png",
    APP_STORE: "/img/widgets/app_store.png",
  },
  SOCIAL_MEDIA: {
    FACEBOOK: "/img/widgets/facebook.png",
    INSTAGRAM: "/img/widgets/instagram.png",
    TWITTER: "/img/widgets/twitter.png",
    SNAPCHAT: "/img/widgets/snapchat.png",
    TIKTOK: "/img/widgets/tik_tok.png",
  },
  GENERIC_LINK: {
    WEBSITE: '/img/widgets/website.png',
    EMAIL: '/img/widgets/email_default.png',
    ARTICLE: '/img/widgets/article.png'
  }
}

const socialHosts = {
  FACEBOOK: "https://facebook.com",
  INSTAGRAM: "https://instagram.com",
  TWITTER: "https://twitter.com",
  SNAPCHAT: "https://story.snapchat.com",
  TIKTOK: "https://tiktok.com",
}

const defaultNames = {
  YOUTUBE: "Youtube",
  TIKTOK: "Tiktok",
  TWITCH: "Twitch",
  VIMEO: "Vimeo",

  SPOTIFY: "Spotify",
  SOUNDCLOUD: "Soundcloud",
  APPLE_MUSIC: "Apple Music",
  APPLE_PODCAST: "Apple Podcast",
  POSHMARK: "Poshmark",
  DEPOP: "Depop",
  ETSY: "Etsy",
  SHOPIFY: "Shopify",
  AMAZON: "Amazon",
  APP_STORE: "App Store",

  FACEBOOK: "Facebook",
  INSTAGRAM: "Instagram",
  TWITTER: "Twitter",
  SNAPCHAT: "Snapchat"
}

const defaultColor = {
  YOUTUBE: "#FA263D",
  TIKTOK: "#303030",
  TWITCH: "#9147FF",
  VIMEO: "#1AB7EA",

  SPOTIFY: "#1ED760",
  SOUNDCLOUD: "#FF5500",
  APPLE_MUSIC: "#FA263D",
  APPLE_PODCAST: "#AD55E7",
  POSHMARK: "#B63A4D",
  DEPOP: "#FF2300",
  ETSY: "#F1641D",
  SHOPIFY: "#95BF46",
  AMAZON: "#231F20",
  APP_STORE: "#1D74F2",

  FACEBOOK: "#0288FF",
  INSTAGRAM: "#DB2F78",
  TWITTER: "#1DA1F3",
  SNAPCHAT: "#FFFC00",

  WEBSITE: '#698DD3',
  ARTICLE: '#421566'
}

const Icons = {
  VIDEO_LINK: {
    YOUTUBE: "/img/widgets/Icons/youtube.png",
    TIKTOK: "/img/widgets/Icons/Tiktok.png",
    TWITCH: "/img/widgets/Icons/Twitch.png",
  },
  APPLICATION_LINK: {
    SPOTIFY: "/img/widgets/Icons/Spotify.png",
    SOUNDCLOUD: "/img/widgets/Icons/Soundcloud.png",
    APPLE_MUSIC: "/img/widgets/Icons/AppleMusic.png",
    APPLE_PODCAST: "/img/widgets/Icons/ApplePodcast.png",
    POSHMARK: "/img/widgets/Icons/Poshmark.png",
    DEPOP: "/img/widgets/Icons/Depop.png",
    ETSY: "/img/widgets/Icons/Esty.png",
    AMAZON: "/img/widgets/Icons/Amazon.png",
    AMAZON_AFFILIATE: "/img/widgets/Icons/Amazon.png",
    APP_STORE: "/img/widgets/Icons/Appstore.png",
  },
  SOCIAL_MEDIA: {
    FACEBOOK: "/img/widgets/Icons/Facebook.png",
    INSTAGRAM: "/img/widgets/Icons/Instagram.png",
    TWITTER: "/img/widgets/Icons/Twitter.png",
    SNAPCHAT: "/img/widgets/Icons/Snapchat.png",
    TIKTOK: "/img/widgets/Icons/Tiktok.png",
  },
  GENERIC_LINK: {
    WEBSITE: '/img/widgets/Icons/Website.png',
    EMAIL: '/img/widgets/Icons/email.png',
    ARTICLE: '/img/widgets/Icons/Article.png'
  }
}