import {Typography} from '@mui/material';
import { Box, Button, Hidden } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';

import UserBox from '../components/UserBox';
import PagesBar from '../components/PagesBar';
import Taggs from '../components/Taggs';
import MobileBottomNav from '../Navigation/MobileBottomNav';
import ProfileNavbar from '../components/ProfileNavbar';
import { APPSTORE_URL } from '../../services/config';
import {useDispatch, useSelector} from "react-redux";
import { copyToClipboard } from '../../services/utils';

import { feedbackAction} from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  btnAddFriend: {
    color: props => {
      return props.primary_color || '#333333'
    },
    width: '100%',
    textTransform: 'capitalize',
    fontSize: 15,
    fontWeight: '700',
    background: props => {
      return props.secondary_color || 'white'
    },
    '&:hover': {
      backgroundColor: props => {
        return props.secondary_color || 'white'
      },
    }
  }
}));

/**
 * User's profile in template 4
 */
export default function Template4({userData, inBackground, ChangeSlide}) {
  const classes = useStyles(userData?.skin);
  const dispatch = useDispatch();
  const onClickShare = () => {
    copyToClipboard(window.location.href);
    dispatch(feedbackAction.showSnack({message: 'Link copied to clipboard'}));
  }
  return (
    <Box className={classes.root}>
      {
        !inBackground && 
        <Hidden mdUp>
          <Box style={{position: 'relative', height: 44, width: '100%'}}>
          <ProfileNavbar />
          </Box>
        </Hidden>
      }
      <UserBox userData={userData} style={{marginTop:"20px"}} />
      <Hidden mdUp>
        <PagesBar userData={userData} active="Home" ChangeSlide={(idx) => ChangeSlide(idx)}/>
      </Hidden>

      <Box style={{margin: '8px 8px 6px'}}>
          <Button className={classes.btnAddFriend}
                  onClick={onClickShare}>
                Share this profile
          </Button>
      </Box>
        
      <Taggs isHome={true} userData={userData} />
      {/* <Hidden mdUp>
        <MobileBottomNav />
      </Hidden> */}
    </Box>
  )
}

Template4.propTypes = {
  /** User's data including name, skin, picture etc */
  userData: PropTypes.object.isRequired,
  /** Whether rendering as background or not, mainly to decide to show profile navbar */
  inBackground: PropTypes.bool
}

Template4.defaultProps = {
  inBackground: false
}