import axios from 'axios';
import { API_URL } from './config';

export const getTopMoments = async (limit, offset) => {
  try {
    const response = await axios.get(`${API_URL}discover_moments/?limit=${limit}&offset=${offset}`);
    const status = response.status;
    if (status === 200) {
      return response.data;
    } else {
      alert('Unable to load moments data');
    }
  } catch (error) {
    alert("Something went wrong. Please refresh the page");
  }
};

export const updateMomentShareCount = async(moment_id) =>{
  const VISITOR_ID = "c1fb3489-d947-459d-abc5-6d5ee02ad945"; //Default web user

  try{
    const res = await axios.post(`${API_URL}record_moment_share/`, {
      'moment_id': moment_id,
      'viewer_id': VISITOR_ID,
      counts: 0
    });

    if(res.status === 200) {
      return true
    }

  } catch(error){
    alert("Something went wrong. Please refresh the page");
  }
};