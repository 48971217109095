import * as React from 'react';
import { Grid, makeStyles, Box } from "@material-ui/core";
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import { useEffect} from 'react';

import { individualTaggCount } from '../../services/UserProfileService'

import Tagg from '../../components/components/Tagg';
import "../../assets/css/taggBar.css";


const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'auto',
    overflowY: 'hidden',
    marginTop: 44,
    width: '100%',
    height: 190,
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    padding: 4
  },
  chip: {
    border: "2px solid #892101 !important",
    backgroundColor: 'transparent !important',
    color: "#892101 !important",
    fontWeight: "700"
  },
  widgetBox: {
    width: 172, display: 'inline-block', margin: 4, flexWrap: 'nowrap'
  }
}));

/**
 * Display widgets
 */
export default function Taggs({isHome, userData, taggs}) {
  const classes = useStyles();
  
  const widgets = isHome ? userData.home_widgets : taggs;
  const [individualTaggdata, setIndividualTaggdata] = React.useState([]);
  const [individualTaggAPIHit, setIndividualTaggAPIHit] = React.useState();
  const [sharedTaggId, setSharedTaggId] = React.useState(null);

  const [isTaggOverlay, setTaggOverlay] = React.useState('taggOverlay');
  let pathSegments = window.location.pathname.split('/');
  
    async function fetchTaggAPI() {
      const data = await individualTaggCount(userData.user.id);
      setIndividualTaggdata(data)
      setIndividualTaggAPIHit(true)
    }
    useEffect(() => {
      
    if (!individualTaggAPIHit) {
      fetchTaggAPI();
    }
    if(pathSegments.length === 5 && pathSegments[1]==='profiles'){
      setSharedTaggId(pathSegments[4]);
    }
  }, [individualTaggAPIHit, pathSegments]);
    useEffect(() => {
      setIndividualTaggAPIHit(false)
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTaggOverlay(null);
    }, 5500);
  }, []); 
      
  return (
    <>
    {!isHome ? 
      <SimpleBar className={classes.root}>
      {
        widgets?.map((widget, idx) => (
          <Box key={widget.id} className={classes.widgetBox}>
            <Tagg userData={userData} isHome={isHome} widget={widget} individualTaggdata={individualTaggdata}/>
          </Box>
        ))
      }
      </SimpleBar>
      :
      <Box style={{width: '100%'}}>
        <Grid container>
        {
          widgets.map((widget, idx) => (
            widget?.active ?
            <Grid key={widget.id} item xs={6} md={6} style={{ padding: '10px'}} className={sharedTaggId===widget.id?'tagActive '+ isTaggOverlay:''}>
              <Tagg userData={userData} isHome={isHome} widget={widget} individualTaggdata={individualTaggdata}/>
            </Grid> : null


          ))
        }
        </Grid>
      </Box>
    }
    </>
  );
}

Taggs.propTypes = {
  /** Whether rendering in profile home or category page */
  isHome: PropTypes.bool,
  /** User's data including home widgets, skin etc */
  userData: PropTypes.object,
  /** Taggs list when rendering in category */
  taggs: PropTypes.array
}

/*
<Grid container spacing={0}>
          {userData.home_widgets.map((widget, idx) => {
            return (
              <Grid key={idx} item xs={6} md={6}>
                <Tagg key={idx} userData={userData} isHome={isHome} src={widget.url} thumbNail={widget.thumbnail_url || widget.moment?.thumbnail_url}/>
              </Grid>
            );
          })}
      </Grid>
*/