import { React, useEffect, useRef, useState } from "react";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import Nav from "../../components/Static/Nav";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import routes from "../../routes";
import Slide1 from "../../assets/images/slider1.png";
import Slide2 from "../../assets/images/slider2.png";
import Slide3 from "../../assets/images/slider3.png";
import Slide4 from "../../assets/images/slider4.png";

export default function Carrers({ ...rest }) {
  return (
    <div>
      <style>{'body{background:#fff;}'}</style>
      <Nav headerClass="" activeMenu="carrers" />
      <div class="careers-intro-section wf-section">
        <div class="careers-into-container">
          <div class="career-intro-text">
            <div class="div-block-21">
              <h1 class="tagg-heading-1-gradient-copy">Careers</h1>
              <h3 class="heading-18">Join our Team!</h3>
            </div>
            <div class="blur-glass-div"></div>
          </div>
        </div>
      </div>
      <div class="carerrs-statement">
        <p class="paragraph">
          Tagg is your place to brand! We believe that in the digital world, the
          better you can brand yourself, the more genuine the connections you
          make will be. We have created an environment that allows for full
          creative expression of oneself with no restrictions or stigma. You
          should be unapologetically you while making friends and keeping up
          with trends!
        </p>
      </div>
      <div class="our-values-section wf-section">
        <div class="our-values-container">
          <div class="our-values-div">
            <div class="our-value-title">
              <h1 class="heading-2">Our Values</h1>
            </div>
            <div className="career-slider">
              <Slider
                arrows={true}
                dots={true}
                infinite={true}
                swipeToSlide
                slidesToShow={1}
              >
                <div>
                  <div class="our-value-contents">
                    <div class="value-content-text">
                      <h2 class="slide-title">1. Unplug from the Matrix</h2>
                      <p class="slide-paragraph">
                        Changing the status quo and wrecking norms is what the
                        startup world is all about. At Tagg, we embrace this
                        pioneer mindset.
                      </p>
                    </div>
                    <div class="value-content-images">
                      <img src={Slide1} />
                    </div>
                  </div>
                </div>
                <div>
                  <div class="our-value-contents">
                    <div class="value-content-text">
                      <h2 class="slide-title">2. Never stop learning</h2>
                      <p class="slide-paragraph">
                        Success depends on everyone&#x27;s willingness to get
                        better at whatever it is they do. The desire to learn is
                        what sets apart good companies and great companies. We
                        are a cross-functional team, jack of all trades, learn
                        to be master of all.
                      </p>
                    </div>
                    <div class="value-content-images">
                      <img src={Slide2} />
                    </div>
                  </div>
                </div>
                <div>
                  <div class="our-value-contents">
                    <div class="value-content-text">
                      <h2 class="slide-title">
                        3. Collaboration overcomes hitting a wall
                      </h2>
                      <p class="slide-paragraph">
                        We believe in the saying “two heads are better than
                        one”. The belief that every single one of us is in this
                        together and giving 100%, is core to our culture.
                        Everyone is willing to lend a helping hand.
                      </p>
                    </div>
                    <div class="value-content-images">
                      <img src={Slide3} />
                    </div>
                  </div>
                </div>
                <div>
                  <div class="our-value-contents">
                    <div class="value-content-text">
                      <h2 class="slide-title">
                        4. There is always room for innovation
                      </h2>
                      <p class="slide-paragraph">
                        As humans, creativity is what drives us as a species and
                        in the startup world, the most avant-garde ones end up
                        changing the world. At Tagg, we want you to be confident
                        in your &quot;crazy Ideas because, without that none of
                        us would be here.{" "}
                      </p>
                    </div>
                    <div class="value-content-images">
                      <img src={Slide4} />
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div class="career-posting wf-section">
        <div class="jobs-container">
          <div class="jobs-titles">
            <h1 class="heading-8">
              Available Positions
              <br />
              <span class="text-span-3">Now Hiring!</span>
            </h1>
          </div>
          <div class="jobs-div">
            <div class="w-dyn-list">
              <div role="list" class="w-dyn-items">
                <div role="listitem" class="collection-item w-dyn-item">
                  <div class="job-div">
                    <div class="text-block-6">Software Engineer</div>
                    <a
                      href="https://breezy-land-f50.notion.site/Software-Engineer-faec2861f9734a2e8d836dd52a757c8f"
                      target="_blank"
                      class="div-block-5 w-inline-block"
                    >
                      <div class="text-block-7">See Details</div>
                    </a>
                    <link
                      rel="prerender"
                      href="https://breezy-land-f50.notion.site/Software-Engineer-faec2861f9734a2e8d836dd52a757c8f"
                    />
                  </div>
                </div>
                <div role="listitem" class="collection-item w-dyn-item">
                  <div class="job-div">
                    <div class="text-block-6">Senior Software Engineer</div>
                    <a
                      href="https://breezy-land-f50.notion.site/Senior-Software-Engineer-9667ab285d1f43998ff896eb167c8955"
                      target="_blank"
                      class="div-block-5 w-inline-block"
                    >
                      <div class="text-block-7">See Details</div>
                    </a>
                    <link
                      rel="prerender"
                      href="https://breezy-land-f50.notion.site/Senior-Software-Engineer-9667ab285d1f43998ff896eb167c8955"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
