import * as React from 'react';
import { Box, Button, Grid, Hidden, IconButton, Typography } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { makeStyles } from "@material-ui/core/styles";
import {Img} from 'react-image'
import PropTypes from 'prop-types';


import ProfileNavbar from '../components/ProfileNavbar';


import { feedbackAction} from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  btnAddFriend: {
    color: props => {
      return props.primary_color || '#333333'
    },
    width: '100%',
    textTransform: 'capitalize',
    fontSize: 15,
    fontWeight: '700',
    background: props => {
      return props.secondary_color || 'white'
    },
    '&:hover': {
      backgroundColor: props => {
        return props.secondary_color || 'white'
      },
    }
  },
  taggScoreText: {
    fontSize: 15,
    fontWeight: '700',
    textAlign: 'center',
    display:"flex", 
    alignItems:"center", 
    justifyContent:"center",
    fontFamily:"SF Pro Text Bold",
    color: props => {
      return props.secondary_color || 'white'
    }
  },
  userBox: {
    position: "absolute", 
    bottom: "0px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    background: props => {
      return `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${props.primary_color || 'black'} 70%)`
    },
    width: "100%",
    paddingTop: '100px',
    paddingBottom: '20px',
    marginBottom: '-5px'
  },
  userFullName: {
    fontSize: 48,
    lineHeight: '48px',
    color: props => {
      return props.secondary_color || 'white'
    }
  },
  userName: {
    fontSize: 15,
    color: props => {
      return props.secondary_color || 'white'
    }
  },
  coinText: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '17px',
    textAlign: 'center',
    color: props => {
      return props.secondary_color || 'white'
    }
  }
}));

/**
 * User's profile in template 3
 */
export default function Template3({userData, inBackground, ChangeSlide}) {
  const classes = useStyles(userData?.skin);
  return (
    <Box className={classes.root}>
      {
        !inBackground && 
        <Hidden mdUp>
          <ProfileNavbar />
        </Hidden>
      }

      <Grid spacing={0}>
        <Box style={{position: "relative", width: '100%'}}>
          <Box style={{background: userData?.skin.primary_color, height: "427px"}}>
            <Img src={userData?.user.header_pic} height="427px" width="100%" style={{objectFit: 'cover'}}
              unloader={<img src={'/img/default_header.png'} height="427px" width="100%" alt="header"/>}
              loader={<img src={'/img/default_header.png'} height="427px" width="100%" alt="header"/>}/>
          </Box>
        </Box>
      </Grid>

        

       
    </Box>
  )
}

Template3.propTypes = {
  /** User's data including name, skin, picture etc */
  userData: PropTypes.object.isRequired,
  /** Whether rendering as background or not, mainly to decide to show profile navbar */
  inBackground: PropTypes.bool
}

Template3.defaultProps = {
  inBackground: false
}