import { createStore } from 'redux';
import rootReducer from './reducers';
// import getRootMiddleware from './MiddlewareRegistry';

// Redux: Store
const store = createStore(
   rootReducer, // 
   undefined,
  //  getRootMiddleware(),
//   applyMiddleware(
//     createLogger(),
//   ),
);

// Exports
export {
  store,
};