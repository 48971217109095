import React, {Component} from "react";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import Nav from "../../components/Static/Nav";
import pdfFile from './document/TaggPrivacyPolicyNov2021.pdf';
import pdfPrivacy from '../../assets/img/tagg-privacy-policy-nov.2021.pdf';
import routes from "../../routes";
class Privacy extends Component {
  render() {
  return (
    <div>
      <style>{'body{background:#fff;}'}</style>
      <Nav headerClass=""/>
      <div className="terms-of-serice-intro-container">
        <div className="terms-of-service-div">
          <img
            src="./img/logo-transparent-white-02.png"
            loading="lazy"
            sizes="50vw"
            srcset="./img/logo-transparent-white-02-p-500.png 500w, ./img/logo-transparent-white-02-p-800.png 800w, ./img/logo-transparent-white-02-p-1080.png 1080w, ./img/logo-transparent-white-02-p-1600.png 1600w, ./img/logo-transparent-white-02.png 2084w"
            alt=""
            className="image-6"
          />
          <div className="div-block-3">
            <h1 className="heading-6">
              <strong className="bold-text-3">Privacy Policy</strong>
            </h1>
          </div>
        </div>
      </div>
      <div className="tagg-info-container">
        <div className="tagg-text-01">
          <p className="paragraph-3">
            Tagg App Inc. respects your right to privacy. This Privacy Policy
            explains who we are, how we collect, share and use information about
            you and how you can exercise your privacy rights. <br />
            We use your Personal data to provide and improve the Service. By
            using the service, you agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
          <h1 className="heading-7">
            <strong className="bold-text-4">
              Interpretation and Definitions
            </strong>
          </h1>
          <p className="paragraph-3">
            <strong className="bold-text-2">Interpretations</strong>​<br />
            <br />
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
            <br />
            <br />‍<strong className="bold-text-2">Definitions</strong>​<br />
            <br />
            For the purposes of this Privacy Policy:
            <br />
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or <br />
            other legal entity on behalf of which such individual is accessing
            or using the <br />
            Service, as applicable. <br />
            Under GDPR (General Data Protection Regulation), You can be referred
            to as the <br />
            Data Subject or as the User as you are the individual using the
            Service. <br />
            <strong>Company</strong> (referred to as either &quot;the
            Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this <br />
            Agreement) refers to Tagg App Inc. , 780 Sutter Ave, Palo Alto, CA
            94303. <br />
            For the purpose of the GDPR, the Company is the “Data Controller”.{" "}
            <br />
            <strong>Application</strong> means the software program provided by
            the Company downloaded by you on any electronic device, named “Tagg”{" "}
            <br />
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by or is under common control with a party, where
            &quot;control&quot; means ownership of 50% or more of the shares,
            equity interest or other securities entitled to vote for election of
            directors or other managing authority. <br />
            <strong>Account</strong> means a unique account created for You to
            access our Service or parts of our Service. <br />
            <strong>Service</strong> refers to the Application. <br />
            <strong>Country</strong> refers to: Texas, United States <br />
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on <br />
            behalf of the Company. It refers to third-party companies or
            individuals employed <br />
            by the Company to facilitate the Service, to provide the Service on
            behalf of the <br />
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used. For the purpose of the
            GDPR, Service Providers <br />
            are considered Data Processors. <br />
            <strong>Third-party Social Media Service</strong> refers to any
            website or any social network <br />
            website through which a User can log in or create an account to use
            the Service. <br />
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable <br />
            individual. <br />
            For the purposes for GDPR, Personal Data means any information
            relating to You <br />
            such as a name, an identification number, location data, online
            identifier or to one or more factors specific to the physical,
            physiological, genetic, mental, economic, cultural or social
            identity. <br />
            For the purposes of the CCPA, Personal Data means any information
            that identifies, relates to, describes or is capable of being
            associated with, or could reasonably be linked, directly or
            indirectly, with You. <br />
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a <br />
            cellphone or a digital tablet. <br />
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).{" "}
            <br />
            <strong>Data Controller</strong>, for the purposes of the GDPR
            (General Data Protection <br />
            Regulation), refers to the Company as the legal person which alone
            or jointly with <br />
            others determines the purposes and means of the processing of
            Personal Data. <br />
            <strong>Do Not Track (DNT)</strong> is a concept that has been
            promoted by US regulatory <br />
            authorities, in particular the U.S. Federal Trade Commission (FTC),
            for the <br />
            Internet industry to develop and implement a mechanism for allowing
            internet <br />
            users to control the tracking of their online activities across
            websites. <br />
            <strong>Business</strong>, for the purpose of the CCPA (California
            Consumer Privacy Act), refers to <br />
            the Company as the legal entity that collects Consumers&#x27;
            personal information and determines the purposes and means of the
            processing of Consumers&#x27; personal information, or on behalf of
            which such information is collected and that alone, or jointly with
            others, determines the purposes and means of the processing of
            consumers&#x27; personal information, that does business in the
            State of California. <br />
            <strong>Consumer</strong>, for the purpose of the CCPA (California
            Consumer Privacy Act), means a natural person who is a California
            resident. A resident, as defined in the law, <br />
            includes (1) every individual who is in the USA for other than a
            temporary or <br />
            transitory purpose, and (2) every individual who is domiciled in the
            USA who is <br />
            outside the USA for a temporary or transitory purpose. <br />
            <strong>Sale</strong>, for the purpose of the CCPA (California
            Consumer Privacy Act), means selling, renting, releasing,
            disclosing, disseminating, making available, transferring, or
            otherwise communicating orally, in writing, or by electronic or
            other means, a Consumer&#x27;s Personal information to another
            business or a third party for monetary or other valuable
            consideration.
          </p>
        </div>
        <div className="tagg-text-02">
          <h1 className="heading-7">
            <strong className="bold-text-4 _2">
              What does Tagg App Inc. do?
              <br />
            </strong>
          </h1>
          <p className="paragraph-3 _2">
            If you have any questions or concerns about our use of your
            information, then please <br />
            contact us using the contact details provided in the “Contact Us”
            section of this Privacy Policy. <br />
            <br />
            Tagg App Inc. is a US headquartered provider of consumer service for
            branding and connection. For more information about Tagg App Inc.
            please see our website <br />
            <a href="#">https://www.tagg.id/</a>.{" "}
            <strong>
              <br />
            </strong>
          </p>
        </div>
        <div className="tagg-text-01">
          <h1 className="heading-7">
            <strong className="bold-text-4">
              Collecting and Using Your Personal Data
              <br />
            </strong>
          </h1>
          <p className="paragraph-3">
            <strong className="bold-text-2">Types of Data collected</strong>​
            <br />
            <br />‍
            <strong>
              Personal Data
              <br />
              <br />​
            </strong>
            While using Our Service, We may ask You to provide Us with certain
            personally <br />
            identifiable information that can be used to contact or identify
            You. Personally identifiable information may include, but is not
            limited to: <br />
            <br />
            Email address <br />
            First name and last name <br />
            Phone number <br />
            Usage Data
            <br />‍<br />‍
            <strong>
              Usage Data​
              <br />
              <br />‍
            </strong>
            Usage Data is collected automatically when using the Service. <br />
            <br />
            Usage Data may include information such as Your Device&#x27;s
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that You visit, the time and date
            of Your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data. <br />
            <br />
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data. <br />
            <br />
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
            <br />‍<br />‍
            <strong>
              Information from Third-Party Social Media Services
              <br />‍<br />​
            </strong>
            The Company allows You to create an account and log in to use the
            Service through the following <br />
            Third-party Social Media Services: <br />
            <br />● Facebook <br />● Google <br />● Pinterest <br />● Twitch{" "}
            <br />● Twitter <br />
            <br />
            If You decide to register through or otherwise grant us access to a
            Third-Party Social Media Service, We may collect Personal data that
            is already associated with Your Third-Party Social Media
            Service&#x27;s account, such as Your name, Your email address, Your
            activities or Your contact list associated with that account. <br />
            <br />
            You may also have the option of sharing additional information with
            the Company through Your Third-Party Social Media Service&#x27;s
            account. If You choose to provide such information and Personal
            Data, during registration or otherwise, You are giving the Company
            permission to use, share, and store it in a manner consistent with
            this Privacy Policy.
            <br />
            <br />
            <br />‍
            <strong>
              Information Collected while Using the Application
              <br />
              <br />‍
            </strong>
            While using Our Application, in order to provide features of Our
            Application, We may collect, <br />
            with your prior permission but not limited to: <br />
            <br />● Information from your Device&#x27;s phone book (contacts
            list) <br />● Pictures and other information from your Device&#x27;s
            camera and photo library <br />
            <br />
            We use this information to provide features of Our Service, to
            improve and customize Our Service. The information may be uploaded
            to the Company&#x27;s servers and/or a Service Provider&#x27;s
            server or it be simply stored on Your device. <br />
            <br />
            You can enable or disable access to this information at any time,
            through Your Device settings.​
            <br />
          </p>
        </div>
        <div className="tagg-text-02">
          <h1 className="heading-7">
            <strong className="bold-text-4 _2">
              How Data is collected
              <br />
            </strong>
          </h1>
          <p className="paragraph-3 _2">
            We collect information about how you use Tagg, such as the types of
            content you view or engage with; the features you use; the actions
            you take; the people or accounts you interact with; and the time,
            frequency and duration of your activities. <br />
            <br />
            We do this by collecting kinds of information from your device, like
            how you tap and scroll, which can help distinguish humans from bots,
            detect fraud and continuously positively improve the experience you
            are having on our platform.
            <strong>
              <br />
            </strong>
          </p>
        </div>
        <div className="tagg-text-01">
          <h1 className="heading-7">
            <strong className="bold-text-4">Use of Personal Data</strong>
          </h1>
          <p className="paragraph-3">
            The Company may use Personal Data for the following purposes: <br />{" "}
            ● <strong>To provide and maintain our Service</strong>, including to
            monitor the usage of our Service. <br />●{" "}
            <strong>To manage Your Account</strong>: to manage Your registration
            as a user of the Service. The Personal Data You provide can give You
            access to different functionalities of the Service that are
            available to You as a registered user. <br />●
            <strong> For the performance of a contract:</strong> the
            development, compliance and undertaking of the purchase contract for
            the products, items or services You have purchased or of any other
            contract with Us through the Service. <br />●{" "}
            <strong>To contact You:</strong> To contact You by email, telephone
            calls, SMS, or other equivalent forms of electronic communication,
            such as a mobile application&#x27;s push notifications regarding
            updates or informative communications related to the
            functionalities, products or contracted services, including the
            security updates, when necessary or reasonable for their
            implementation. <br />● <strong>To provide You:</strong> with news,
            special offers and general information about other goods, services
            and events which we offer that are similar to those that you have
            already purchased or enquired about unless You have opted not to
            receive such information. <br />●{" "}
            <strong>To manage Your requests:</strong> To attend and manage Your
            requests to Us. <br /> <br />
            We may share your personal information in the following situations:{" "}
            <br /> <br />● <strong>With Service Providers:</strong> We may share
            Your personal information with Service Providers to monitor and
            analyze the use of our Service, to contact You. <br />●
            <strong> For Business transfers:</strong> We may share or transfer
            Your personal information in <br />
            connection with, or during negotiations of, any merger, sale of
            Company assets, financing, or acquisition of all or a portion of our
            business to another company. <br />●{" "}
            <strong>With Affiliates:</strong> We may share Your information with
            Our affiliates, in which case we will require those affiliates to
            honor this Privacy Policy. Affiliates include Our parent company and
            any other subsidiaries, joint venture partners or other companies
            that We control or that are under common control with Us. <br />●{" "}
            <strong>With Business partners: </strong>We may share Your
            information with Our business partners to offer You certain
            products, services or promotions. <br />●{" "}
            <strong>With other users:</strong> when You share personal
            information or otherwise interact in the public areas with other
            users, such information may be viewed by all users and may be
            publicly distributed outside. If You interact with other users or
            register through a Third-Party Social Media Service, Your contacts
            on the Third-Party Social Media Service may see Your name, profile,
            pictures and description of Your activity. Similarly, other users
            will be able to view descriptions of Your activity, communicate with
            You and view Your profile.
            <br />
          </p>
          <div className="read-pdf">
            <a
              href={pdfPrivacy}
              target="_blank"
              className="button-4 w-button"
              rel="noopener noreferrer"
            >
              View Full PDF
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
}
export default Privacy;