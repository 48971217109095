import { MOMENT_SET_DATA } from "../actionTypes"

const setMomentsData = payload => {
  return ({
    type: MOMENT_SET_DATA,
    payload
  })
}

export default {
  setMomentsData
}