import {Box, Button, IconButton, Modal, Typography} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { APPSTORE_URL, APP_LINK } from '../../services/config';
import { getMobileOperatingSystem } from '../../services/utils';

/**
 * A modal to let users signup or download app
 */
export default function SignupModal({open, img_path, title, body, onClose}) {
  const classes = useStyles();
  const gotoAppStore = () => {
    onClose && onClose();
    let deviceType = getMobileOperatingSystem();
    let now = new Date().valueOf();
    switch (deviceType) {
      case 'iOS':
        setTimeout(function () { 
          if (new Date().valueOf() - now > 200) return;
          window.open(APPSTORE_URL, '_blank'); 
        }, 25);
        window.location.replace(APP_LINK.iOS);
        break;
      default:
        window.open(APPSTORE_URL, '_blank')
    }
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box className={classes.root}>
        <IconButton className={classes.closeBtn} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <img src={img_path} alt="" />
        <Typography className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.body}>
          {body}
        </Typography>
        <Button 
          className={classes.button}
          onClick={gotoAppStore}>
          Sign Up
        </Button>
        <Box className={classes.horizontal}>
          <Typography className={classes.t2} style={{color: '#828282'}}>
            Already have the app?
          </Typography>
          <Button onClick={gotoAppStore} className={classes.btnLogin}>Login</Button>
        </Box>
        
      </Box>
    </Modal>
  )
}

SignupModal.propTypes = {
  /** Whether the modal is visible or not */
  open: PropTypes.bool,
  /** Function to call when the modal gets closed */
  onClose: PropTypes.func,
  /** Path to image to show */
  img_path: PropTypes.string,
  /** Modal's title */
  title: PropTypes.string,
  /** Modal's main description */
  body: PropTypes.string,
}

SignupModal.defaultProps = {
  open: false
}


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    maxWidth: '90vw',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: 24,
    padding: '24px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: "center"
  },
  title: props => ({
    fontSize: 25,
    fontWeight: '700',
    color: 'black',
    marginTop: 8,
    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
    },
  }),
  body: props => ({
    fontSize: 18,
    fontWeight: '500',
    marginTop: 8,
    color: '#828282',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  }),
  closeBtn: {
    alignSelf: 'flex-end'
  },
  button: {
    background:"linear-gradient(94.81deg, #8F01FF 2.44%, #6EE7E7 100%)",
    color: "white",
    width:"151px",
    margin: "24px 0px 8px 0px",
    fontSize: 18,
    fontWeight: '700',
    textTransform: 'none'
  },
  t2: {
    fontSize: 15,
    fontWeight: '400'
  },
  horizontal: {
    display: 'flex',
    alignItems: 'center'
  },
  btnLogin: {
    color: '#698DD3', 
    textDecoration: 'underline',
    textTransform: 'none',
    padding: '6px 4px',
    minWidth: 0
  }
}));