export const copyToClipboard = (content) => {
  navigator.clipboard.writeText(content); // clipboard only works on secure origin
  // const el = document.createElement("input");
  // el.value = content;
  // el.setAttribute("readonly", "");
  // el.style.position = "absolute";
  // el.style.left = "-9999px";
  // document.body.appendChild(el);
  // el.select();
  // el.setSelectionRange(0, 99999);
  // document.execCommand("copy");
  // document.body.removeChild(el);
};

export const safeValue = (val, low, high) => {
  let safe = val > high ? high : val;
  safe = safe < low ? low : safe;
  return safe;
}

export const typeOfMedia = (path) => {
  const images = ["jpg", "jpeg", "gif", "png"]
  const videos = ["mp4", "3gp", "ogg"]

  const url = new URL(path)
  const segments = url.pathname.split(".");
  const extension = segments.length ? segments[segments.length-1].toLowerCase() : '';

  if (images.includes(extension)) return 'img';
  if (videos.includes(extension)) return 'video';
  return '';
}

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
      return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
  }

  return "unknown";
}

export const shareToSocialMedia = (social, url) => {
  let socialType = social?.toLowerCase();
  switch (socialType) {
    case 'pinterest':
      window.open(`https://pinterest.com/pin/create/button/?url=${url}`, "_blank");
      break;
    case 'twitter':
      window.open(`https://twitter.com/share?url=${url}`, "_blank");
      break;
    case 'facebook':
      window.open(`https://www.facebook.com/sharer.php?u=${url}`, "_blank");
      break;
    case 'instagram':
      break;
    case 'imessage':
      break;
  }
}

export const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace('#', '');
  
  if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }    
  
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
}

export const validateWidgetThumbnailUrl = (url) => {
  if (!url || url === "https://tagg-prod.s3.us-east-2.amazonaws.com/misc/not+found.jpg") {
    return false;
  }
  return true;
}