import {Box, Button, IconButton, Modal, Typography} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';

const socials = [
  { title: 'Pinterest', icon: '/img/icon/pinterest.png' },
  { title: 'Twitter', icon: '/img/icon/twitter.png' },
  { title: 'Facebook', icon: '/img/icon/facebook.png' },
  // { title: 'Instagram', icon: '/img/icon/instagram.png' },
  // { title: 'IMessage', icon: '/img/icon/imessage.png' },
];

/**
 * A bottom modal to copy to clipboard or share to social platforms
 */
export default function ShareModal({open, onClose, handleAction}) {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box className={classes.root}>
        
        
        <Typography className={classes.shareTitle}>
          Share to
        </Typography>

        <Box style={{display: 'flex', alignItems: 'center', marginTop: 10, width:'100%', overflowX:'auto', justifyContent:'space-between',paddingBottom:'10px'}}>
          <Button className={classes.btnSocial} key='copylink' onClick={() => handleAction('Copy')}>
            <Box>
              <img src={'/img/icon/copylink.svg'} height='30' width='30' alt='copylink' />
              <Typography noWrap className={classes.socialTitle}>{'Copy Link'}</Typography>
            </Box>
          </Button>
          {
            socials.map(social => (
              <Button className={classes.btnSocial} key={social.title} onClick={() => handleAction(social.title)}>
                <Box>
                  <img src={social.icon} height='30' width='30' alt={social.title} />
                  <Typography className={classes.socialTitle}>{social.title}</Typography>
                </Box>
              </Button>
            ))
          }
        </Box>

       <Box className={classes.cancelBox} onClick={onClose}>
          <Typography className={classes.cancelText} >
            Cancel
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
}

ShareModal.propTypes = {
  /** Whether the modal is visible or not */
  open: PropTypes.bool,
  /** Function to call when the modal gets closed */
  onClose: PropTypes.func,
  /** Handler function when an action is invoked */
  handleAction: PropTypes.func
}

ShareModal.defaultProps = {
  open: false
}


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    left: '50%',
    bottom: '0',
    transform: 'translate(-50%, 0)',
    width: '100%',
    maxWidth: '100vw',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: 24,
    padding: '24px 32px',
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: "center"
  },
  socialTitle: props => ({
    fontSize: 10,
    fontWeight: '500',
    lineHeight: '15px',
    letterSpacing: 0,
    textAlign:'center',
    color:  '#828282',
    marginTop: 8,
    fontFamily: 'SF Pro Text Regular',
    textTransform: 'capitalize'
  }),
  shareTitle: {
    fontSize: 17,
    fontWeight: '700',
  },
  cancelBox: {
    height:'82px',
    width:'100vw',
    backgroundColor: '#F0F0F0',
  },
  cancelText: {
    textAlign: 'center',
    justifyContent:'center',
    margin:'auto',
    lineHeight: '82px',
    fontWeight:'700',
    color:'#698DD3'
  },
  body: {
    fontSize: 18,
    fontWeight: '500',
    marginTop: 8,
    color: '#828282'
  },
  closeBtn: {
    alignSelf: 'flex-end'
  },
  button: {
    background:"linear-gradient(94.81deg, #8F01FF 2.44%, #6EE7E7 100%)",
    color: "white",
    width:"151px",
    margin: "24px 0px 8px 0px",
    fontSize: 18,
    fontWeight: '700',
    textTransform: 'none'
  },
  t2: {
    fontSize: 15,
    fontWeight: '400'
  }
}));