import {useEffect, useRef, useState} from 'react';
import { Box, Typography } from '@material-ui/core';
import Card from '@mui/material/Card';
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import {Img} from 'react-image'
import PropTypes from 'prop-types';

import { loadProfileInfo } from '../../../services';
import { validateImageLink } from '../../../services/ValidateUrlService';
import Template1 from '../../Templates/TopProfileTemplate1';
import Template2 from '../../Templates/TopProfileTemplate2';
import Template3 from '../../Templates/TopProfileTemplate3';
import Template4 from '../../Templates/TopProfileTemplate4';
import Template5 from '../../Templates/TopProfileTemplate5';

/**
 * User's profile card
 */
export default function ProfileCard({user}) {
  const [profile, setProfile] = useState({});
  const classes = useStyles(profile?.skin);

  useEffect(() => {
    fetchProfile();
  }, [])

  /**
   * Fetch profile data with username
   */
  const fetchProfile = async() => {
    try {
      const data = await loadProfileInfo(user?.username);
      
      setProfile(data);
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <Box className={classes.contentWrapper}>
        <Box className={classes.content}>
        { 
          profile?.skin?.template_type === 'ONE'
          ? <Template1 userData={profile} inBackground/>
          : profile?.skin?.template_type === 'TWO'
          ? <Template2 userData={profile} inBackground/>
          : profile?.skin?.template_type === 'THREE'
          ? <Template3 userData={profile} inBackground/>
          : profile?.skin?.template_type === 'FOUR'
          ? <Template4 userData={profile} inBackground/>
          : profile?.skin?.template_type === 'FIVE'
          ? <Template5 userData={profile} inBackground/>
          : null
          }
        </Box>
      
      <Box className={classes.overlay}>
        <Img src={user?.profile_pic} className={classes.userAvatar} alt=""
        loader={<img src="/img/missing.png" className={classes.userAvatar} alt="" /> }
        unloader={<img src="/img/missing.png" className={classes.userAvatar} alt="" /> } />
        <Typography className={classes.txtName}>
          {`${user?.first_name} ${user?.last_name}`}
        </Typography>
        <Typography className={classes.txtNick}>
          @{user?.username}
        </Typography>
        <NavLink to= {"/profiles/"+user?.username} style={{textDecoration: 'none'}}>
          <Box className={classes.btnEnter}>Enter Space</Box>
        </NavLink>
      </Box>
      </Box>
    </Card>
  )
}

ProfileCard.propTypes = {
  /** User's data including name, picture etc */
  user: PropTypes.object.isRequired
}

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  content: {
    width: "100%",
    height: "calc(100vh - 128px)",
    background: props => {
      if (props.primary_color) return props.primary_color;
      switch (props.template_type) {
        case 'ONE':
          return '#F5BECA'
        case 'TWO':
          return 'white';
        case 'FOUR':
          return 'linear-gradient(191.68deg, #C0B6B8 1.48%, #3D79C5 101.38%)';
        default:
          return "black"
      }
    },
    padding: "0px !important",
    [theme.breakpoints.up('sm')]: {
      maxHeight:'400px',
      minHeight:'400px',
      width: "320px !important",
    },
    [theme.breakpoints.down('sm')]: {
      
    },
  },
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
    borderRadius: 8
  },
  userAvatar: {
    width: 67,
    height: 67,
    borderRadius: 67,
    objectFit: 'cover',
    backgroundColor: '#C4C4C4',
  },
  txtName: {
    marginTop: 8,
    fontSize: 20,
    fontWeight: '700',
    color: 'white'
  },
  txtNick: {
    fontSize: 15,
    fontWeight: '600',
    color: 'white'
  },
  btnEnter: {
    backgroundColor: '#698DD3',
    borderRadius: 5,
    color: 'white',
    width: 140,
    maxWidth: '99%',
    marginTop: 16,
    marginBottom: 30,
    fontSize: 15,
    fontWeight: '700',
    textAlign: 'center',
    padding: '8px 12px'
  }
}));