import { FEEDBACK_HIDE, FEEDBACK_SET, FEEDBACK_SET_EXPLORE } from "../actionTypes"

const showSnack = payload => {
  return ({
    type: FEEDBACK_SET,
    payload: {
      ...payload,
      open: true
    }
  })
}

const hideSnack = payload => {
  return ({
    type: FEEDBACK_HIDE,
  })
}

const setExploreModal = (visible, data) => {
  let payload = {
    exploreData: data
  };
  if (visible !== undefined) {
    payload.exploreOpen = visible;
  }

  return ({
    type: FEEDBACK_SET_EXPLORE,
    payload
  })
}

export default {
  showSnack,
  hideSnack,
  setExploreModal
}