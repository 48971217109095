import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import { Snackbar, Alert as MuiAlert } from '@mui/material';
import {useSelector, useDispatch} from "react-redux";
import { useWindowHeight } from '@react-hook/window-size';

import { store } from './redux';
import Home from './layouts/Home';
import UserProfile from './components/Views/TopProfiles/UserProfile';
import MomentsPage from "./layouts/Moment";
import Careers from "./layouts/Static/Careers";
import FrontPage from "./layouts/Static/FrontPage";
import TermsOfService from "./layouts/Static/TermsOfService";
import Privacy from "./layouts/Static/Privacy";
import CommunityGuidelines from "./layouts/Static/CommunityGuidelines";
import TaggOfficeHours from "./layouts/Static/TaggOfficeHours";
import SocialBranding from "./layouts/Static/SocialBranding";
import TheCreatorProblem from "./layouts/Static/TheCreatorProblem";
import ContentCreator from "./layouts/Static/ContentCreator";



import { feedbackAction } from './redux/actions';
import { CheckCircleOutline } from '@material-ui/icons';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const snackIcons = {
	'success': (<CheckCircleOutline style={{fontSize: 30, color: 'white', marginRight: 6}} />),
  'pinterest': (<img src="/img/icon/pinterest.png" width="30px" height="30px" style={{borderRadius: 30}} />),
  'facebook': (<img src="/img/icon/facebook.png" width="30px" height="30px" style={{borderRadius: 30}} />),
  'twitter': (<img src="/img/icon/twitter.png" width="30px" height="30px" style={{borderRadius: 30, backgroundColor: 'white'}} />),
  'instagram': (<img src="/img/icon/instagram.png" width="30px" height="30px" style={{borderRadius: 30}} />),
  'imessage': (<img src="/img/icon/imessage.png" width="30px" height="30px" style={{borderRadius: 30}} />),
}

const snackStyles = {
  'default': {
    width: 540,
    maxWidth: '90vw',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 15,
  },
  'success': {
    backgroundColor: '#3EC23B',
  },
  'pinterest': {
    backgroundColor: '#BD081C',
  },
  'twitter': {
    backgroundColor: '#1DA1F2',
  },
  'facebook': {
    backgroundColor: '#549bf6',
  },
  'instagram': {
    background: "linear-gradient(94.81deg, #6057C8 2.44%, #B932A9 33%, #EB4B52 66%, #FED474 100%)",
  },
  'imessage': {
    background: "linear-gradient(94.81deg, #57C5FA 2.44%, #0B68CB 100%)"
  },

}


function TaggApp() {
  const {open, message, variant, exploreOpen, exploreData} = useSelector(({feedback}) => feedback);
  const dispatch = useDispatch();
  const windowHeight = useWindowHeight();
  const isMobile = !window.matchMedia("(min-width: 960px)").matches;

/*  useEffect(() => {
    dispatch(feedbackAction.setExploreModal(isMobile))
  }, [])
*/
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(feedbackAction.hideSnack());
  };

  /* const onCloseExploreModal = () => {
    dispatch(feedbackAction.setExploreModal(false))
  } */

  let exploreUsername = exploreData?.first_name;
  exploreUsername = !!exploreData?.last_name ? exploreUsername ? `${exploreUsername} ${exploreData.last_name}` : exploreData.last_name : exploreUsername;
  
  return (
    <div style={{...styles.root, height: windowHeight ? `${windowHeight}px`:undefined}}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={FrontPage} />
          <Route exact path='/profiles'>
            <Redirect to="/home" />
          </Route>
          <Route exact path='/home'>
            <Redirect to="/home/profiles" />
          </Route>
          <Route path="/home" component={Home} />
          <Route path="/profiles" component={UserProfile} />
          <Route path="/moments" component={MomentsPage} />
          <Route path="/careers" component={Careers} />
          <Route path="/terms-of-service" component={TermsOfService} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/community-guidelines" component={CommunityGuidelines} />
          <Route path="/tagg-office-hours" component={TaggOfficeHours} />
          
          <Route path="/the-creator-problem-make-1-000-followers-feel-like-1-000-000" component={TheCreatorProblem} />
          <Route path="/can-anyone-be-a-content-creator-7-tips-on-how-to-get-started" component={ContentCreator} />
          <Route path="/what-the-f-ck-is-social-branding" component={SocialBranding} />
          
          
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
        <Alert icon={snackIcons[variant]} style={{...snackStyles.default, ...snackStyles[variant]}}>
          {message}
        </Alert>
      </Snackbar>
      
    </div>
  )
}

function App() {
  return (
    <Provider store={store}>
      <div id="community-embed"></div>
      <script async data-community-embed-id="47b80beb-5934-40b3-a7ab-fba09a976b1c" src="https://mgu-embed.community.com/embed.js"></script>
      <TaggApp />
    </Provider>
  );
}

export default App;

const styles = {
  root: {
    width: '100vw',
    height: '100vh',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    display: 'flex',
    flexDirection: 'column'
  }
}
