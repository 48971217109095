import React from "react";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import Nav from "../../components/Static/Nav";

import routes from "../../routes";

export default function SocialBranding({ ...rest }) {
  return (
    <div>
      <Nav headerClass="transparent-header"/>

      <div className="wf-section">
        <div className="w-container">
          <div className="div-block-26">
            <h1 className="blog-title-2-copy">
              What the f*ck is social branding?
            </h1>
          </div>
          <div className="div-block-25"></div>
          <div className="div-block-27">
            <div className="body-blog w-richtext">
              <h2>
                
                  <strong>Social-Branding</strong>
            
                <br />
              </h2>
              <p>
                Social-branding is the intersectionality of branding yourself
                and the social behavior of connecting. It runs on the doctrine
                of connection through interest-based content creation.
              </p>
              <p>
               It is a marvelous realm
                where a creative can uncover the beauty of manifesting their own
                space online filled with content based on their interests and
                passions all while discovering other creatives through their
                content!
              </p>
              <p>
                You see, Tagg is the only social platform where connecting with
                people happens through branding yourself!
                <br />
              </p>
              <figure className="w-richtext-align-center w-richtext-figure-type-image">
                <div>
                  <img
                    src="./img/-vmkGNQ51pVdnZiPqJifFAt-S6_ZvAYmnmY-6Sp7gvAfIwywkJJjfdBxr5p6gjBZY7HKknUatfQmoHnYQjgXgt6m-YQ28dPo6jysqsoFrYxZ7l_LmbV-AY8dNPNtLp22pWzpMRz5.gif"
                    width="211"
                    alt=""
                  />
                </div>
                <figcaption>The possibilities are endless</figcaption>
              </figure>
              <p>‍</p>
              <h4>
                <strong>Gen Z and Self-branding online</strong>
              </h4>
              <p>
                This idea of social-branding is hugely desirable amongst Gen Z
                as a recent study from Morning Consult revealed that{" "}
                <strong>
                  <em>88%</em>
                </strong>
                of Gen Z considers their online presence their brand and
                understands the avenues this personal brand can be used to take
                advantage of.
              </p>
              <p>
                All that said, it is hard to effectively brand online. This is
                because the current social platforms were not built for this new
                way of socializing. Have you felt like you want to share content
                around a newfound passion like slacklining, dancing, singing,
                etc. but posting it to your IG or Tik Tok would be off brand for
                you? That is exactly what we are talking about. 
              </p>
              <p>
                Currently self-branding online has a bunch of negative
                connotations but we want to restructure the vibe around it
                because we Gen Z’ers happen to communicate through branding. For
                example: most times, even before we get to know someone, we
                naturally make a read on them by the aesthetic they have
                captured on social.
                <br />
              </p>
              <p>Some of the negative connotations include:</p>
              <ul role="list">
                <li>
                  Current social platforms are not built for multiple
                  interest-based content sharing. This obviously pushes you to
                  present yourself in a very one-dimensional manner. For
                  example: if I create cooking content and garner a community
                  around that, it becomes incredibly hard to show the other
                  things I am passionate about like fitness or gaming for fear
                  of clashing. So many creatives are eventually faced with this
                  tension between content I will enjoy creating vs content I
                  feel my community wants to see.
                </li>
                <li>
                  Taking the initial steps of self-branding online can be a bit
                  isolating as you would naturally feel your friends and
                  community on social might see you as vain or a try-hard for
                  sharing the content you want to. You might feel like some
                  would even mute you and the pressure of all this makes it hard
                  to continuously confidently share.
                </li>
              </ul>
              <figure className="w-richtext-align-center w-richtext-figure-type-image">
                <div>
                  <img src="./img/office-gif.gif" loading="lazy" alt="" />
                </div>
                <figcaption>Unfortunate but we got you</figcaption>
              </figure>
              <p>
                <strong>‍</strong>
                <br />
              </p>
              <h4>
                <strong>Why Tagg?</strong>
              </h4>
              <p>
                Tagg is social branding for Gen-Z micro-creatives. A place for
                creatives to build a brand and connect with other creatives. A
                community of like-minded people all growing together!
              </p>
              <p>
                We live in a world where creating your own space/vibes is as
                easy as pressing “record” on your phone. We see this through Tik
                Tok trends or posting aesthetically pleasing/coherent photo
                dumps on Instagram. Yet there is still this toxicity towards
                this idea of branding.
              </p>
              <p>
                Tagg lets creatives connect and build with each other in an
                environment where nothing is off brand but everything you do,
                adds to the brand you are building! We want you to expand past
                content creation of what you have already gone viral for, and
                into content creation based on your interest and passions. Tagg
                is a place where you can always be your favourite self!
              </p>
              <p>
                We are ultimately evening the playing field of self-branding
                online. Allowing everyone to feel comfortable when it comes to
                branding themselves to meet people who align with their
                presence. 
              </p>
              <p>‍</p>
              <figure
                style={{maxWidth:1280}}
                className="w-richtext-align-fullwidth w-richtext-figure-type-image"
              >
                <div>
                  <img
                    src="./img/phone-mockup.png"
                    loading="lazy"
                    width="559"
                    alt=""
                  />
                </div>
              </figure>
              <p>
                ‍<strong>‍</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-11-copy wf-section">
        <div className="w-container">
          <div className="text-block-12">Text us to stay updated</div>
          <div className="div-block-23">
            <div className="text-block-15">
              <strong className="bold-text-5">
                Let us spam you, respectfully
              </strong>
            </div>
            <img
              src="images/9378-fuckboi.png"
              loading="lazy"
              width="30"
              sizes="30px"
              srcset="images/9378-fuckboi-p-500.png 500w, images/9378-fuckboi.png 540w"
              alt=""
            />
          </div>
          <div className="div-block-30">
            <a
              href="http://my.community.com/tagg"
              target="_blank"
              className="button w-button"
            >
              Subscribe
            </a>
          </div>
          <div className="div-block-17">
            <div className="w-form">
              <form
                id="email-form-2"
                name="email-form-2"
                data-name="Email Form 2"
                method="get"
                className="form"
              >
                <input
                  type="tel"
                  className="text-field-5 w-input"
                  maxlength="256"
                  name="name-3"
                  data-name="Name 3"
                  placeholder="Enter your phone number"
                  id="name-3"
                />
                <input
                  type="submit"
                  value="Subscribe"
                  data-wait="Please wait..."
                  className="button w-button"
                />
              </form>
              <div className="success-message-2 w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div className="text-block-13">
                  Oops! Something went wrong while submitting the form.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
