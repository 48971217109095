import {Typography} from '@mui/material';
import { Box, Button, Hidden } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';


import ProfileNavbar from '../components/ProfileNavbar';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  btnAddFriend: {
    color: props => {
      return props.primary_color || '#333333'
    },
    width: '100%',
    textTransform: 'capitalize',
    fontSize: 15,
    fontWeight: '700',
    background: props => {
      return props.secondary_color || 'white'
    },
    '&:hover': {
      backgroundColor: props => {
        return props.secondary_color || 'white'
      },
    }
  }
}));

/**
 * User's profile in template 4
 */
export default function Template4({userData, inBackground, ChangeSlide}) {
  const classes = useStyles(userData?.skin);

  return (
    <Box className={classes.root}>
      {
        !inBackground && 
        <Hidden mdUp>
          <Box style={{position: 'relative', height: 44, width: '100%'}}>
          <ProfileNavbar />
          </Box>
        </Hidden>
      }
      
        
      </Box>
  )
}

Template4.propTypes = {
  /** User's data including name, skin, picture etc */
  userData: PropTypes.object.isRequired,
  /** Whether rendering as background or not, mainly to decide to show profile navbar */
  inBackground: PropTypes.bool
}

Template4.defaultProps = {
  inBackground: false
}