import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useHistory, NavLink } from 'react-router-dom';
import { Box, Button, Container, Grid, CssBaseline, Hidden, styled } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackIos } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from 'simplebar-react';
import { ScrollView } from 'react-native';
import VisibilityIcon from '@mui/icons-material/Visibility';

import SharedProfiles from '../SharedProfile/SharedProfiles';
import Template1 from '../../Templates/Template1';
import Template2 from "../../Templates/Template2";
import Template3 from "../../Templates/Template3";
import Template4 from "../../Templates/Template4";
import Template5 from "../../Templates/Template5";
import CategoryPage from '../../Templates/CategoryPage';
import { loadProfileInfo, loadPageContent, visitedUserProfile, linkedUserProfile, GetProfileViews, UpdateProfileViews } from '../../../services';
import { copyToClipboard } from '../../../services/utils';
import { feedbackAction, profileAction, momentAction } from '../../../redux/actions';
import SignupModal from '../../modals/SignupModal';

import mixpanel from 'mixpanel-browser';
import { MixPanel } from '../../../services/config';


import MomentsSwipe from '../../Views/Moments/MomentsSwipe';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { EffectCreative, Mousewheel, Controller } from 'swiper';
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import 'swiper/modules/effect-fade/effect-fade';
import "swiper/modules/navigation/navigation";
import "swiper/modules/pagination/pagination";

import { Text, View, TouchableOpacity, StyleSheet, Dimensions, Platform, NativeModules } from 'react-native'
import ScrollPagedView, { ViewPaged } from 'react-scroll-paged-view'
import Loader from "../../components/Loader"
import { useGeolocated } from "react-geolocated";
// install Swiper modules
//SwiperCore.use([EffectCreative, Mousewheel]);
/**
 * Show's user profile
 */
export default function UserProfile() {

  const location = useLocation();
  let pathSegments = location.pathname.split('/');
  const [userName, setUserName] = useState(pathSegments[2]);
  const { data: userData, page } = useSelector(({ profile }) => profile);
  const [loading, setLoading] = useState(true);
  const [profileViews, setProfileView] = useState(true);
  const [CurrentIndex, setCurrentIndex] = useState(0);
  const classes = useStyles(userData?.skin);
  const [userID, setUserID] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [allPages, setAllPages] = React.useState([]);
  const [moments, setMoments] = React.useState([]);
  const [sharedUserData, setSharedUserData] = useState(null);
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });
  /**
   * Fetch user data
   */
  async function getUserData() {
    try {
      setLoading(true);
      const data = await loadProfileInfo(userName);
      if (data.tagg_score > 12000) {
        data.user.tier = 5;
      } else if (data.tagg_score > 9000) {
        data.user.tier = 4;
      } else if (data.tagg_score > 2999) {
        data.user.tier = 3;
      } else if (data.tagg_score > 999) {
        data.user.tier = 2;
      }
      dispatch(profileAction.setProfileData({ data }));
      //dispatch(feedbackAction.setExploreModal(undefined, data.user))
      const dd = [];
      setUserID(data.user.id);
      for (const d of data?.pages) {
        const ddd = await loadPageContent(data.user.username, d);
        dd.push(ddd)
        break;
      }
      setAllPages(dd);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    mixpanel.init(MixPanel.random, { debug: true });
  });

  useEffect(() => {
    if (userName) {
      getUserData();
      if (history.action == 'POP') {
        linkedUserProfile(userName);
      }
    }
  }, [userName])


  useEffect(() => {

    const getUserData = async (sharedUserName) => {
      const userData = await loadProfileInfo(sharedUserName);
      setSharedUserData(userData);
    };
    let sharedUserName = new URLSearchParams(window.location.search).get('username');
    if (sharedUserName) {
      getUserData(sharedUserName);
    }

  }, []);
  // Profile count
  useEffect(() => {
    if (userID) {
      let location = {latitude:'', longitude:''};
      if ( isGeolocationAvailable && isGeolocationEnabled ) {
        if(coords != undefined && coords.latitude != undefined && coords.longitude != undefined){  
          location = {latitude:coords.latitude, longitude: coords.longitude}
          visitedUserProfile(userID, location );
        }
      }else{
        visitedUserProfile(userID, location );
      }
    }
  }, [userID, coords])


  // Setting the profile views and update it
  useEffect(() => {
    let pathSegments = location.pathname.split('/');

    if (pathSegments.length > 3) {
      if (pathSegments[1] === 'profiles') {
        dispatch(profileAction.setProfileData({ page: 'Home' }));
      } else {
        dispatch(profileAction.setProfileData({ page: pathSegments[3] }));
      }

      //third segment of path
      //  console.log(pathSegments[3]);
    } else {
      dispatch(profileAction.setProfileData({ page: 'Home' }));
    }

    setUserName(pathSegments[2]);

    const userName = pathSegments[2];

    mixpanel.identify(userName);

    mixpanel.track(`Profile Visits Counts (WebApp)`, {
      count: 1,
      username: userName
    });

    const date = new Date().toISOString().substring(0, 10);
    GetProfileViews(userName, date).then((data) => {
      if (data === 401) {
        setProfileView(1);
        UpdateProfileViews(userName, date, 1);
      } else {
        const count = data.counts + 1;
        setProfileView(count);
        UpdateProfileViews(userName, date, count);
      }
    });

  }, [location.pathname]);

  useEffect(() => {
    document.body.style.backgroundColor = userData?.skin?.primary_color;
  }, []);

  const onClickShare = () => {
    copyToClipboard(window.location.href);
    dispatch(feedbackAction.showSnack({ message: 'Link copied to clipboard' }));
  }

  const onPressTab = (tab) => {
    // setPage(tab);
    if (tab === '__TaggUserHomePage__') {
      history.push(`/profiles/${userName}`)
    } else {
      dispatch(momentAction.setMomentsData({ data: moments }));
      history.push(`/moments/${userName}/${tab}/`);
    }
    // setTemplate({"template": test[tab]});
  }
  const { width: screenWidth, height: screenHeight } = Dimensions.get('window')
  const ScrlView = React.useRef();
  const ChangeSlide = (idx) => {
    //firstSwiper.slideTo(idx)
    setCurrentIndex(idx)
    const scrollPoint = idx * screenWidth
    ScrlView.current.scrollTo({ x: scrollPoint, y: 0, animated: true })
  }

  const handleScroll = (e) => {
    let indx = e.nativeEvent.contentOffset.x / screenWidth
    setCurrentIndex(Math.round(indx))
  }

  const validPages = userData?.pages;
  return (
    loading ? <Loader /> :
      <React.Fragment>
        <CssBaseline />

        <Hidden smDown>
          <Box sx={{ backgroundColor: 'black', padding: '24px 12px' }}>
            <Container >
              <Grid container>
                <Grid item md={6}>
                </Grid>
                <Grid item md={6}>
                  <Box className={classes.sectionNav} style={{ justifyContent: "flex-end" }}>
                    <Button className={classes.horizontal} style={{ color: 'white', textTransform: 'none', fontSize: 18, fontWeight: '700' }}
                      onClick={onClickShare}>
                      <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.16745 22C0.455341 22 0.128895 21.4311 0.0548675 21.1458C-0.835274 8.84508 9.32606 5.4851 14.5178 5.34279V0.751197C14.9627 -0.273802 15.89 -0.031696 16.2977 0.217438L25.7541 9.61391C26.1994 10.0409 25.9397 10.575 25.7541 10.7883L16.2977 20.3984C14.8738 20.8253 14.5178 19.9356 14.5178 19.4373V15.0594C7.21969 14.6324 3.35543 19.01 2.27968 21.2526C2.16846 21.4845 1.87957 22 1.16745 22Z" fill="white" />
                      </svg>
                      &nbsp;&nbsp;Share profile
                    </Button>
                  </Box>
                </Grid>
              </Grid>

            </Container>
          </Box>
        </Hidden>
        <Grid container className={classes.gridContainer}>
          {sharedUserData ? <SharedProfiles userData={sharedUserData} /> : ''}
          <Grid item xs={12} md={6}>
            <SimpleBar className={classes.container}>
              {/* <Container  className={classes.container} > */}
              {
                page !== 'Home'
                  ? <CategoryPage userData={userData} page={page} />
                  : userData.skin.template_type === 'ONE'
                    ? <Template1 userData={userData} />
                    : userData.skin.template_type === 'TWO'
                      ? <Template2 userData={userData} />
                      : userData.skin.template_type === 'THREE'
                        ? <Template3 userData={userData} />
                        : userData.skin.template_type === 'FOUR'
                          ? <Template4 userData={userData} />
                          : userData.skin.template_type === 'FIVE'
                            ? <Template5 userData={userData} />
                            : null
              }
              {/* </Container> */}
            </SimpleBar>
          </Grid>
          <Hidden smDown>
            <Grid item md={6}>
              <Box className={classes.rightSection}>
                <p className={classes.title}>Navigate {userData?.user?.username}'s Profile</p>
                {validPages.map((pg, idx) => (
                  <CustomButton key={idx} className={page === pg || (page === 'Home' && pg === '__TaggUserHomePage__') ? "contained" : 'outlined'} onClick={() => onPressTab(pg)}>
                    {pg === '__TaggUserHomePage__' ? 'Home' : pg}
                  </CustomButton>
                ))
                }
                <p className={classes.text} style={{ color: 'white' }}>Want one? Too bad 😝... </p>
                <SignButton
                  onClick={() => setModalOpen(true)}>
                  Sign up
                </SignButton>
                <p className={classes.tinyTxt} style={{ color: 'white' }}>Just kidding sign up to get started</p>

                {/* <p className={classes.text} style={{color: 'white', marginTop: 20}}>Profile Views</p> */}
                {/* <div><VisibilityIcon style={{color: 'white', paddingTop: 2.3}}/> <span className={classes.text1} style={{color: 'white'}}>{profileViews}</span></div> */}
              </Box>
            </Grid>
          </Hidden>
        </Grid>
        <SignupModal open={modalOpen} img_path="/img/join_comment.png"
          title="Join the conversation" body="You won’t believe what they are saying 👀"
          onClose={() => setModalOpen(false)} />
      </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    // marginTop: "0px !important",
    flex: 1,
    backgroundColor: 'black',
    [theme.breakpoints.up('md')]: {
      // height: 'calc(100vh - 91px)',
    },
  },
  container: {
    maxWidth: "100vw",
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: "0px !important",
    overflowY: "auto",
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '812px',
      minHeight: '812px',
      maxWidth: "375px !important",
      // '-ms-overflow-style': 'none',
      // scrollbarWidth: 'none',
      // "&::-webkit-scrollbar": {
      //   display: 'none',
      // }
      '& .simplebar-track': {
        display: 'none'
      }
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: "100vh"
    },
    background: props => {
      if (props.primary_color) return props.primary_color;
      switch (props.template_type) {
        case 'ONE':
          return '#F5BECA'
        case 'TWO':
          return 'white';
        case 'FOUR':
          return 'linear-gradient(191.68deg, #C0B6B8 1.48%, #3D79C5 101.38%)';
        default:
          return "black"
      }
    },
  },
  sectionNav: {
    display: "flex",
    alignItems: 'center',
    height: '100%'
  },
  horizontal: {
    display: 'flex',
    alignItems: 'center'
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 64,
    marginBottom: 64,
  },
  title: {
    fontSize: 22,
    color: 'white',
    fontWeight: '700'
  },
  text: {
    fontSize: 18,
    fontWeight: '700'
  },
  text1: {
    fontSize: 18,
    fontWeight: '700',
    lineHeight: -1.7
  },
  tinyTxt: {
    fontSize: 11,
    fontWeight: '600'
  },
}));

const CustomButton = styled(Button)({
  '&.contained': {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 18,
    fontWeight: '700',
    wordBreak: 'break-word',
    padding: '16px 16px',
    border: '2px solid white',
    lineHeight: 1.5,
    backgroundColor: 'white',
    borderRadius: 100,
    color: 'black',
    width: '50%',
    marginBottom: 24,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.8)',
      borderColor: 'white',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgba(255,255,255,0.8)',
      borderColor: 'white',
    },
    // '&:focus': {
    //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    // },
  },
  '&.outlined': {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 18,
    fontWeight: '700',
    wordBreak: 'break-word',
    padding: '16px 16px',
    border: '2px solid white',
    lineHeight: 1.5,
    backgroundColor: 'black',
    borderRadius: 100,
    color: 'white',
    width: '50%',
    marginBottom: 24,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.8)',
      borderColor: 'white',
      boxShadow: 'none',
      color: 'black',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgba(255,255,255,0.8)',
      borderColor: 'white',
      color: 'black',
    },
  }
});

const SignButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 18,
  fontWeight: '700',
  padding: '12px 26px',
  lineHeight: 1.5,
  background: 'linear-gradient(94.72deg, #8F01FF 0.32%, #09FCFC 100.32%)',
  borderRadius: 3,
  color: 'white',
  width: 188,
  margin: "16px 0px",
  '&:hover': {
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
  },
});

const styles = StyleSheet.create({
  scroller: {
    paddingHorizontal: 0,
  },
});