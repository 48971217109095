import { React, useEffect, useRef, useState} from 'react';
import Slider from "react-slick";
import { useLocation, useHistory } from 'react-router-dom';
import {Button, IconButton, Box, Grid, Hidden, Typography, TextField} from "@material-ui/core";
import { NavLink } from 'react-router-dom';
import FavoriteIcon from '@material-ui/icons/Favorite';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {Img} from 'react-image';
import { useWindowHeight } from '@react-hook/window-size';

import MobileBottomNav from '../../Navigation/MobileBottomNav';
import { drawerWidth } from "../../../assets/jss/material-dashboard-react";
import { loadPageContent, loadProfileInfo } from '../../../services';
import { copyToClipboard, safeValue, shareToSocialMedia, typeOfMedia } from '../../../services/utils';
import { feedbackAction, momentAction } from '../../../redux/actions';
import SignupModal from '../../modals/SignupModal';
import ShareModal from '../../modals/ShareModal';
import { APPSTORE_URL, APP_LINK } from '../../../services/config';
import MyMedia from '../../media';
import { getMobileOperatingSystem } from '../../../services/utils';
import { ContactsOutlined } from '@material-ui/icons';
import { updateMomentShareCount } from '../../../services/momentService';
const socials = [
  { title: 'Pinterest', icon: '/img/icon/pinterest.png' },
  { title: 'Twitter', icon: '/img/icon/twitter.png' },
  { title: 'Facebook', icon: '/img/icon/facebook.png' },
  // { title: 'Instagram', icon: '/img/icon/instagram.png' },
  // { title: 'IMessage', icon: '/img/icon/imessage.png' },
];

/**
 * User's moments
 */
export default function Moments({userData, moments, isCurrent, pageTitle, onClose}) {
  const dispatch = useDispatch()
  const data = moments; //useSelector(({moments}) => moments);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showTitle, setShowTitle] = useState(true);
  const [link, setLink] = useState('');
  const [comments, setComments] = useState([]);
  const [commentsOpened, setCommentsOpened] = useState(false);
  const [addFriendModalOpen, setAddFriendModalOpen] = useState(false);
  const [joinModalOpen, setJoinModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const location = useLocation();
  const isMobile = !window.matchMedia("(min-width: 960px)").matches;
  const classes = useStyles();
  const windowHeight = useWindowHeight();
  const slideRef = useRef();
  const currentItem = data[currentIndex] ?? {};
  const displayedComments = currentItem.top_comment?.comment_id ? [currentItem.top_comment] : []; //commentsOpened ? comments : comments.slice(0,1);

  useEffect(() => {
    return () => {
      window.ontouchmove = null;
    }
  }, []);

  // For view more button
  const gotoAppStore = () => {
    onClose && onClose();
    let deviceType = getMobileOperatingSystem();
    let now = new Date().valueOf();
    switch (deviceType) {
      case 'iOS':
        setTimeout(function () { 
          if (new Date().valueOf() - now > 200) return;
          window.open(APPSTORE_URL, '_blank'); 
        }, 25);
        window.location.replace(APP_LINK.iOS);
        break;
      default:
        window.open(APPSTORE_URL, '_blank')
    }
  }
  /**
   * Fetch data if necessary, and set initial moment
   */
  useEffect(() => {
    setShowTitle(true);
    let pathSegments = location.pathname.split('/');
    let userName = pathSegments[2];
    setLink(`${window.location.origin}/moments/${userName}/${encodeURI(pageTitle)}`);

    if (pathSegments.length < 4) {
      //window.location.href = '/';
      return;
    }
    
    //let userName = pathSegments[2];
    let page = pathSegments[3];
    let initialId = pathSegments[4] ?? '';
    if (data.length) {
      let initialIndex = data.findIndex(d => d.moment_id === initialId);
      initialIndex = safeValue(initialIndex, 0, data.length-1);
      setCurrentIndex(initialIndex);
      slideRef.current?.slickGoTo(initialIndex);
    } else {
      fetchData(userName, page, initialId);
    }
    setLink(`${window.location.origin}/moments/${userName}/${encodeURI(page)}`);
  }, [location.pathname]);
 
  /**
   * Fetch data
   * @param {*} userName 
   * @param {*} page 
   * @param {*} initialId 
   */
  const fetchData = async(userName, page, initialId) => {
    let res = await loadPageContent(userName, page);
    if (res) {
      let moments = res.moments ?? [];
      let initialIndex = moments.findIndex(d => d.moment_id === initialId);
      initialIndex = safeValue(initialIndex, 0, moments.length-1);
      setCurrentIndex(initialIndex);
      dispatch(momentAction.setMomentsData({data: moments}));
      slideRef.current?.slickGoTo(initialIndex);

      if (moments.length > 0) {
       // dispatch(feedbackAction.setExploreModal(undefined, moments[0].user));
      } else {
        res = await loadProfileInfo(userName);
        //dispatch(feedbackAction.setExploreModal(undefined, res.user))
      }
    }
  }
  /**
   * Format number as friendly way
   * @param {*} num 
   * @returns formatted string
   */
  const formatNumber = (num) => {
    if (num >= 100000000)
      return (num/1000000).toFixed(0) + 'M';
    if (num >= 1000000)
      return (num/1000000).toFixed(1) + 'M';
    if (num >= 100000)
      return (num/1000).toFixed(0) + 'K';
    if (num >= 10000)
      return (num/1000).toFixed(1) + 'K';
    return num;
  }

  const onSwipeItem = (index, indexLatest, meta) => {
    setCurrentIndex(index);
    setCommentsOpened(false);
    if(index === 0){
      setShowTitle(true);
    }else{
      setShowTitle(false);
    }

  }

  const changeIndexBy = (by) => {
    let newIndex = safeValue(currentIndex + by, 0, data.length-1);
    setCurrentIndex(newIndex);
    slideRef.current?.slickGoTo(newIndex);
    setCommentsOpened(false);
    if(newIndex === 0){
      setShowTitle(true);
    }else{
      setShowTitle(false);
    }

  }

  /**
   * Copy current link to clipboard
   */
  const handleCopy = () => {
    copyToClipboard(`${link}/${currentItem.moment_id}`);
    dispatch(feedbackAction.showSnack({message: 'Link copied to clipboard'}))
  }

  const onMoreCommentClick = () => {
    // setCommentsOpened(!commentsOpened)
    setJoinModalOpen(true);
  }

  /**
   * Handle user's action
   * @param {*} action 
   */
  const handleShareAction = (action) => {
    let shareURL = currentItem.moment_url;
    let shareItemId = currentItem.moment_id;
    let shareCount = updateMomentShareCount(shareItemId);
    
    switch (action) {
      case 'Copy':
        handleCopy();
        break;
      default:
        dispatch(feedbackAction.showSnack({message: `Redirecting to ${action}`, variant: action.toLowerCase()}))
        setTimeout(() => {
          shareToSocialMedia(action, shareURL);
        }, 1000);
        break;
    }
    setShareModalOpen(false);
  }

  const onSignup = () => {
    setAddFriendModalOpen(false);
    window.open(APPSTORE_URL, '_blank');
  }

  const fullHeight = `${windowHeight? windowHeight+'px':'100vh'}`;
  // console.log("data", data, currentIndex);
  //let pageTitle = location.pathname.split('/')[3];
  pageTitle = pageTitle === '__TaggUserHomePage__' ? 'Home' : pageTitle;

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item md={6} xs={12}>
          <Box style={{display: 'flex', flex: 1}}>
            <Box style={{width: !isMobile ? `calc(100% - ${drawerWidth}px)`: '100%', height: fullHeight}}>
            {
              !!data.length && 
              <Slider ref={slideRef} initialSlide={currentIndex}
              vertical draggable arrows={false} dots={false} infinite={false}
              swipeToSlide slidesToShow={1} verticalSwiping
              afterChange={onSwipeItem}
              >
                {
                  data.map((d, index) => (
                    <div key={d.moment_id} className={classes.slide} >
                      <Box style={{height: fullHeight}}>
                      <MyMedia src={d.moment_url} className={classes.slideBack} isCurrent={isCurrent ? (currentIndex === index) : null} />
                      <Hidden mdDown>
                      <Box className={classes.slideInner}>
                        {index === 0 ? (<h3 style={{fontFamily: 'SF Pro Text Bold',fontSize: '18px', margin:'0', textShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)'}}>{showTitle ? pageTitle : null}</h3>) : null }
                      </Box>
                      </Hidden>
                      <Hidden mdUp>
                        <Box className={classes.slideInner}>
                        {index === 0 ? (<h3 style={{fontFamily: 'SF Pro Text Bold',fontSize: '18px', margin:'0', textShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)'}}>{showTitle ? pageTitle : null}</h3>) : null }
                        <div className={classes.mobileBottom}>
                          <div className={classes.mobileBottomLeft}>
                            <Box className={classes.mobileUserBox}>
                              <NavLink to={`/profiles/${d.user?.username}`} className={classes.actionButton}>
                                <Img src={[d.user?.profile_pic, '/img/missing.png']} className={classes.mobileAvatar} />
                              </NavLink>
                              <Box style={{ marginLeft: 12, flex: 1 }}>
                                <Typography className={classes.mobileUsername}>{d.user?.username} {/*d.user?.last_name*/}</Typography>
                                <Typography className={classes.mobileViews}>{formatNumber(d.view_count)} Views</Typography>
                              </Box>
                            </Box>
                            <Typography className={classes.t2b} style={{marginTop: 12}}>{d.caption}</Typography>
                          </div>
                          <div className={classes.mobileBottomRight} style={{position:'relative', top:'15px'}}>
                            <div>
                            <Typography className={`${classes.mobileViews} ${classes.tc} size`}><Img src={['/img/mobile_coin.png']} style={{ width: 40, height: 40, objectFit: 'cover', borderRadius: 36, marginBottom: 8 }} />&nbsp;{Math.trunc((d.view_count/5)-((d.view_count/5) % 10))}
                            </Typography>                                
                            </div>
                            <div style={{ marginTop: 32 }} onClick={() => setShareModalOpen(true)} className={classes.actionButton}>
                              <img src="/img/icon/Sharebtn.png" width={32} alt=""/>
                              <Typography className={`${classes.mobileViews} ${classes.tc}`}>{d.share_count > 0 ? formatNumber(d.share_count) : 'Share'}</Typography>
                            </div>
                            <div style={{ marginTop: 24 }}>
                              {/* <Button onClick={onMoreCommentClick} className={classes.actionButton} style={{padding: 6, minWidth: 0}}>
                                <img src="/img/icon/cmnt.png" width={32}/>
                              </Button> */}
                              {/* <Typography className={`${classes.mobileViews} ${classes.tc}`}>{formatNumber(d.comments_count)}</Typography> */}
                              {/* <Typography className={`${classes.mobileViews} ${classes.tc}`}>Comment</Typography> */}
                            </div>
                            <Typography className={classes.t3} style={{marginTop: 36}}>
                              {d.created_at}
                            </Typography>
                          </div>
                        </div>
                        </Box>
                      </Hidden>
                      </Box>
                    </div>
                  ))
                }
              </Slider>
            }
            
            </Box>
            <Hidden smDown>
              <Box className={classes.swipeControlBox}>
                <IconButton className={classes.btnSwipe} style={{visibility: data.length > 1 && currentIndex > 0 ? 'visible' : 'hidden'}}
                onClick={() => changeIndexBy(-1)}>
                  <KeyboardArrowUpIcon className={classes.swipeIcon} />
                </IconButton>
                <IconButton className={classes.btnSwipe} 
                style={{visibility: data.length > 1 && currentIndex < data.length-1 ? 'visible': 'hidden'}}
                onClick={() => changeIndexBy(1)}>
                  <KeyboardArrowDownIcon className={classes.swipeIcon} />
                </IconButton>
              </Box>
            </Hidden>
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid item md={6} >
            <Box style={{backgroundColor: 'white', padding: 24, overflowY: 'auto'}}>
              <Box style={{ paddingBottom: 16, borderBottom: '1px solid #DADADA'}}>
                <Box style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                  <NavLink to={`/profiles/${currentItem.user?.username}`}>
                    <Img src={[currentItem.user?.profile_pic, '/img/missing.png']} style={{ width: 64, height: 64, objectFit: 'cover', borderRadius: 64 }} 
                    key={currentItem.user?.profile_pic || 'Key'} />
                  </NavLink>
                  <Box style={{ marginLeft: 12, flex: 1 }}>
                    <Typography className={classes.t1}>
                      <a href={`/profiles/${currentItem.user?.username}`} style={{color:"#000"}}>
                        {currentItem.user?.username} {/*currentItem.user?.last_name*/}
                      </a>
                    </Typography>
                    <Typography className={classes.t2} style={{color: '#828282', marginTop: 10}}>{formatNumber(currentItem.view_count)} Views</Typography>
                  </Box>
                  <Button className={`${classes.btnAdd}`} variant='contained'
                    onClick={gotoAppStore}>
                    View More
                  </Button>
                </Box>
                <Typography className={classes.t1m}>{currentItem.caption}</Typography>
              </Box>
              {/* {
                displayedComments.length > 0 ? displayedComments.map((comment, index) => (
                  <Box key={comment.comment_id} className={classes.comment}>
                    <a href={`/profiles/${comment?.commenter?.username}`}>
                      <Img src={[comment.commenter?.profile_pic, '/img/missing.png']} className={classes.commentAvatar} />
                    </a>
                    <Box style={{ marginLeft: 8, flex: 1 }}>
                      <Typography className={classes.t2b}>
                        <a href={`/profiles/${comment?.commenter?.username}`} style={{color:"#000"}}>
                          {comment.commenter?.first_name} {comment.commenter?.last_name}
                        </a>
                      </Typography>
                      <Typography className={classes.t2}>{comment.comment}</Typography>
                    </Box>
                    <Box className={classes.commentLike}>
                      <IconButton onClick={() => setJoinModalOpen(true)}>
                        <FavoriteIcon className={classes.likeIcon} />
                      </IconButton>
                      <Typography className={classes.likeCount}>{formatNumber(comment.like_count)}</Typography>
                    </Box>
                  </Box>
                ))
                : <Box className={classes.comment}>
                  <Typography className={classes.t2} style={{color: '#333333'}}>Comment</Typography>
                </Box>
              }
              {
                displayedComments.length >= 1 ?
                <Button className={classes.t2} style={{marginLeft: 40, color: '#698DD3', textTransform: 'none'}}
                onClick={onMoreCommentClick}
                endIcon={!commentsOpened ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}>
                  {!commentsOpened ? 'more' : 'less'}
                </Button>
                : null
              } */}
              <Box style={{display: 'flex', alignItems: 'center', marginTop: 24}}>
                <Typography className={classes.t2b} style={{marginRight: 6}}>Share to:</Typography>
                {
                  socials.map(social => (
                    <Button className={classes.btnSocial} key={social.title} onClick={() => handleShareAction(social.title)}>
                      <Box>
                        <img src={social.icon} className={classes.socialIcon} />
                        <Typography className={classes.socialTitle}>{social.title}</Typography>
                      </Box>
                    </Button>
                  ))
                }
              </Box>
              <Box style={{display: 'flex'}}>
                <input className={classes.copyTextBox} disabled value={`${link}/${currentItem.moment_id}`} />
                <Button className={classes.btnCopy} onClick={handleCopy}>
                  Copy Link
                </Button>
              </Box>
            </Box>
            <Box className={classes.signBox}>
              <Typography className={classes.t1}>Want to see more?</Typography>
              <Typography className={classes.t2} style={{color: '#828282', marginTop: 8}}>Sign up now to see all Tagg has to offer</Typography>
              <Button className={classes.btnSign} onClick={() => setJoinModalOpen(true)}>Sign up</Button>
              <Typography className={classes.t2} style={{color: '#828282', marginTop: 8}}>
                Already have an account?&nbsp;&nbsp;
                <a href="#" onClick={() => setJoinModalOpen(true)} style={{color: '#698DD3', textDecoration: 'none', cursor: 'pointer'}}>Login</a>
              </Typography>
               
            </Box>
          </Grid>
        </Hidden>
      </Grid>
      
      <SignupModal open={addFriendModalOpen} img_path="/img/friends.png"
      title="Connect with other creatives" body="On Tagg friendships foster creativity" 
      onClose={() => setAddFriendModalOpen(false)} />
      <SignupModal open={joinModalOpen} img_path="/img/join_comment.png"
      title="Join the conversation" body="You won’t believe what they are saying 👀" 
      onClose={() => setJoinModalOpen(false)} />
      <ShareModal open={shareModalOpen} onClose={() => setShareModalOpen(false)} handleAction={handleShareAction} />
      {/* <Hidden mdUp>
        <MobileBottomNav />
      </Hidden> */}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  slide: {
    width: '100%', 
    height: '100%', 
    backgroundColor: 'black',
    position: 'relative',
    display: 'block !important'
  },
  slideBack: {
    width: '100%', 
    height: '100%',
    objectFit: 'cover'
  },
  slideInner: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    padding: "30px 15px 80px 15px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
    pointerEvents: 'none'
  },
  swipeControlBox: {
    width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    flex: 1
  },
  btnSwipe: {
    width: 48,
    height: 48,
    borderRadius: 48,
    border: '1px solid white',
    margin: '12px 0px',
    // backgroundColor: "#698DD3",
    color: 'white',
    '&:hover': {
      // backgroundColor: "#698DD3",
    }
  },
  swipeIcon: {
    fontSize: 56
  },
  btnAdd: {
    fontSize: 18,
    fontWeight: '700',
    backgroundColor: "#698DD3",
    textTransform: 'none',
    padding: '6px 26px',
    color: 'white',
    '&:hover': {
      backgroundColor: "#698DD3",
    }
  },
  btnCopy: {
    fontSize: 13,
    fontWeight: '700',
    border: "2px solid #698DD3",
    textTransform: 'none',
    padding: '6px 22px',
    color: '#698DD3',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    '&:hover': {
      // backgroundColor: "#698DD3",
    }
  },
  copyTextBox: {
    padding: '8px 16px',
    border: '2px solid #DADADA',
    borderRadius: 3,
    fontSize: 13,
    backgroundColor: '#F0F0F0',
    color: '#828282',
    flex: 1,
  },
  t1: {
    fontSize: 18,
    fontWeight: '700',
    color: "black"
  },
  t2: {
    fontSize: 15,
    fontWeight: '600'
  },
  comment: {
    display: 'flex',
    margin: '16px 0',
  },
  t2b: {
    fontSize: 15,
    fontWeight: '700'
  },
  commentAvatar: {
    width: 40,
    height: 40,
    objectFit: 'cover',
    borderRadius: 40,
  },
  commentLike: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  likeCount: {
    fontSize: 13,
    fontWeight: '600',
    color: "#828282"
  },
  likeIcon: {
    fill: '#D7443E',
    fontSize: 20
  },
  socialIcon: {
    width: 30,
    height: 30,
    objectFit: 'cover',
  },
  btnSocial: {
    margin: 6,
  },
  socialTitle: {
    fontSize: 11,
    color: '#828282',
    textTransform: 'none'
  },
  signBox: {
    padding: '50px 0',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#F0F0F0',
    marginTop: '8px',
    height: '100%'
  },
  btnSign: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 18,
    fontWeight: '700',
    padding: '22px 76px',
    lineHeight: 1.5,
    background: 'linear-gradient(94.72deg, #8F01FF 0.32%, #09FCFC 100.32%)',
    borderRadius: 3,
    color: 'white',
    margin: "24px 0px",
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },

  mobileUserBox: {
    display: 'flex',
    alignItems: 'center',
    color: "white",
  },
  mobileUsername: {
    fontSize: 15,
    fontWeight:'700'
  },
  mobileViews: {
    fontSize: 13,
    fontWeight: '600',
    marginTop: 8,
  },
  size: {
    fontSize: 13,
    fontWeight: '600',
    marginTop: 8,
  },
  
  mobileAvatar: {
    width: 48, 
    height: 48, 
    objectFit: 'cover', 
    borderRadius: 48
  },
  mobileBottom: {
    marginTop: 'auto',
    display: "flex",
    alignItems: 'flex-end',
    width: '100%',
    // marginBottom: 28,
  },
  mobileBottomLeft: {
    flex: 1,
  },
  mobileBottomRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  t3: {
    fontSize: 13,
    fontWeight: '500',
    color: "white",
  },
  t1m: {
    fontSize: 18,
    fontWeight: '500',
    color: "black"
  },
  tc: {
    textAlign: 'center'
  },
  actionButton: {
    pointerEvents: 'auto'
  }
}));