// Retrieves User's name, username, tagg score, bio, friend count, 
// Header img, profile img, list of categories, home taggs
import axios from 'axios';
import { API_URL, BASE_URL } from './config';

// Example endpoint
// https://app-prod3.tagg.id/api/web/profile/?username=pat144

const USER_PROFILE_ENDPOINT = API_URL + "profile/?username=";
const PROFILE_VIEW_COUNT_ENDPOINT = BASE_URL + "api/insights/profile/";
const PROFILE_LINK_COUNT_ENDPOINT = BASE_URL + "api/insights/profile_link_view/";
const VISITOR_ID = "c1fb3489-d947-459d-abc5-6d5ee02ad945"; //Default web user
const TAG_CLICK_COUNT_ENDPOINT = BASE_URL + "api/insights/taggs";

export const loadProfileInfo = async (username) => {
  try {
    const response = await axios.get(USER_PROFILE_ENDPOINT + `${username}`);
    const status = response.status;
    if (status === 200) {
      console.log(response.data);
      return response.data;
    } else {
      alert('Unable to load profile data');
    }
  } catch (error) {
    alert("Something went wrong. Please refresh the page");
  }
};


export const GetProfileViews = async (username, date) => {
  try {
    const response = await axios.get(`http://127.0.0.1:8000/api/web_profile/traffic_count/1/?date=${date}&profiles=${username}`);  
    if(response.status === 200) {
      return response.data;
    }
  
  } catch(error) {
    const res = await axios.post(`http://127.0.0.1:8000/api/web_profile/traffic_count/`, {
      date: date,
      profiles: username,
      counts: 0
    });

    if(res.status === 200) {
      return 401
    }
  }
}

export const UpdateProfileViews = async (username, date, count) => {
  const response = await axios.put(`http://127.0.0.1:8000/api/web_profile/traffic_count/1/`, {
      date: date,
      profiles: username,
      counts: count
  });  
  
  if(response.status === 200) {
    console.log("Dataset Updated");
  }
}

export const visitedUserProfile = async (userId, location) => {
var data = new FormData();
data.append('user_id', userId);
data.append('location', JSON.stringify(location));
data.append('visitor_id', VISITOR_ID);

var config = {
  method: 'post',
  url: PROFILE_VIEW_COUNT_ENDPOINT,
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
};

export const linkedUserProfile = async (username) => {
  var data = new FormData();
  data.append('username', username);

  var config = {
    method: 'post',
    url: PROFILE_LINK_COUNT_ENDPOINT,
    data : data
  };
  
  axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  });
  };

export const individualTaggCount = async (userId) => {
  try {
    const response = await axios.get(TAG_CLICK_COUNT_ENDPOINT, {
      params: {
        filter_type: 'LIFETIME',
        user_id: userId
      }
    });
    const status = response.status;
    if (status === 200) {
      return response.data;
      // console.log(response.data);
    } else {
      console.log('Unable to load Tag Insight');
    }
  } catch (error) {
    alert("Something went wrong. Please refresh the page");
  }
}