import * as React from 'react';
import { Box, Button, Hidden } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';

import UserBox from '../components/UserBox';
import PagesBar from '../components/PagesBar';
import Taggs from '../components/Taggs';
import MobileBottomNav from '../Navigation/MobileBottomNav';
import ProfileNavbar from '../components/ProfileNavbar';
import {Img} from 'react-image'
import { APPSTORE_URL } from '../../services/config';
import {useDispatch, useSelector} from "react-redux";
import { copyToClipboard } from '../../services/utils';

import { feedbackAction} from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  content: {
    position: 'relative',
    backgroundColor: props => {
      return props.primary_color || '#F5BECA'
    },
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    top: -20,
    paddingTop: 1
  },
  bioBox: {
    borderRadius: 8,
    padding: '16px 18px',
    textAlign: 'center',
    fontWeight: '600',
    margin: '16px 10px' ,
    fontSize: 14,

    background: props => {
      if (props.bio_color_start && props.bio_color_end) {
        return `linear-gradient(95.05deg, ${props.bio_color_start} 0%, ${props.bio_color_end} 100%)`
      }
      return props.bio_color_start || '#808080'; // `linear-gradient(95.05deg, ${props.primary_color || '#5101FF'} 0%, ${props.secondary_color || '#9E6BFE'} 100%)`;
    },
    color: props => {
      if (props.template_type === "ONE") {
        return props.primary_color || 'white'
      }else{
      return props.bio_text_color || 'white'
      }
    }
  },
  btnAddFriend: {
    color: props => {
      return props.primary_color || '#333333'
    },
    width: '100%',
    textTransform: 'capitalize',
    fontSize: 15,
    fontWeight: '700',
    background: props => {
      return props.secondary_color || '#A2352C'
    },
    '&:hover': {
      backgroundColor: props => {
        return props.secondary_color || '#698DD3'
      },
    }
  }
}));


/**
 * User's profile in template 1
 */
export default function Template1({userData, inBackground, ChangeSlide}) {
  const classes = useStyles(userData?.skin);
  const dispatch = useDispatch();
  const onClickShare = () => {
    copyToClipboard(window.location.href);
    dispatch(feedbackAction.showSnack({message: 'Link copied to clipboard'}));
  }
  return (
    <Box className={classes.root}>
      {
        !inBackground &&
        <Hidden mdUp>
          <ProfileNavbar />
        </Hidden>
      }

      <Box style={{height: "350px"}}>
        <Img src={userData.user.header_pic} width="100%" 
            unloader={<img src={'/img/default_header.png'} height="100%" width="100%" alt="header"/>}
            loader={<img src={'/img/default_header.png'} height="100%" width="100%" alt="header"/>}/>
      </Box>

      <Box className={classes.content}>
        <Hidden mdUp>
          <PagesBar userData={userData} active="Home" ChangeSlide={(idx) => ChangeSlide(idx)}/>
        </Hidden>

        <UserBox userData={userData} />
        {
        userData.user.biography ? 
        <Box className={classes.bioBox}>{userData.user.biography}</Box>
        : null 
        }
        
        { userData.user.biography ? <Box style={{margin: '0 10px 20px'}}> 
        <Button className={classes.btnAddFriend}
                  onClick={onClickShare}>
            Share this profile
          </Button>
        </Box>
        : <Box style={{margin: '10px 10px 20px'}}>
          <Button className={classes.btnAddFriend}
                  onClick={onClickShare}>
            Share this profile
          </Button>
        </Box> }
        <Taggs isHome={true} userData={userData} />
      </Box>
      {/* <Hidden mdUp>
        <MobileBottomNav />
      </Hidden> */}
    </Box>
  )
}

Template1.propTypes = {
  /** User's data including name, skin, picture etc */
  userData: PropTypes.object.isRequired,
  /** Whether rendering as background or not, mainly to decide to show profile navbar */
  inBackground: PropTypes.bool
}

Template1.defaultProps = {
  inBackground: false
}