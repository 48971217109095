import './SharedProfile.css';
export default function SharedProfiles({userData}) {
    return (
        <div className="pfBox">
            <div className="pfLeftBox">
            <a href={`/profiles/${userData.user.username}`}>
                <img src={userData.user.profile_pic} className="jss215" 
                unloader={<img src={'/img/default_header.png'} height="100%" width="100%" alt="header"/>}
                loader={<img src={'/img/default_header.png'} height="100%" width="100%" alt="header"/>}
                />
            </a>
            <div className="pfText">
                <h3>{`${userData.user.first_name} ${userData.user.last_name}`} </h3>
                <p>Join me on Tagg!</p></div>
            
            </div>
        
            <a href="https://apps.apple.com/us/app/tagg-earn-for-being-engaging/id1537853613" target="_blank"><button className="btn btnopenApp" >Open app</button></a>
    </div>
    )
}