import * as React from 'react';
import { Box, Grid} from '@mui/material';
import { Button, IconButton, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import {Img} from 'react-image'
import PropTypes from 'prop-types';


import ProfileNavbar from '../components/ProfileNavbar';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  btnAddFriend: {
    color: props => {
      return props.primary_color || 'white'
    },
    width: '100%',
    textTransform: 'capitalize',
    fontSize: 15,
    fontWeight: '700',
    background: props => {
      return props.secondary_color || '#698DD3'
    },
    '&:hover': {
      backgroundColor: props => {
        return props.secondary_color || '#698DD3'
      },
    }
  },
  cutBox: {
    height:"80px",
    position: "absolute", 
    bottom: "0px",
    width: "100%",
    WebkitTransform: "skew(-60deg)",
    marginLeft:"70px",
    textAlign: 'center',
    justifyContent: 'center',
    color: 'black',
    background: props => {
      return props.primary_color || 'white'
    }
  },
  tierBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bioText: {
    fontSize: 13,
    fontWeight: '600',
    marginBottom: 8,
    color: props => {
      return props.bio_text_color || 'black'
    }
  },
  userName: {
    color: props => {
      return props.bio_text_color || '#828282'
    },
    fontSize: 13,
    fontWeight: '600'
  }
}));

/**
 * User's profile in template 2
 */
export default function Template2({userData, inBackground, ChangeSlide}) {
  const classes = useStyles(userData?.skin);


  return (
    <Box className={classes.root}>
      {
        !inBackground && 
        <Hidden mdUp>
          <ProfileNavbar />
        </Hidden>
      }

      <Grid sx={{height:"88px", position: "relative"}} container>
        <Grid item xs={12} md={12} sx={{height:"288px", background: userData.skin.primary_color, width:"357px"}}>
          <Img src={userData.user.header_pic} height="288px" width="100%" 
            unloader={<img src={'/img/default_header.png'} height="288px" width="100%" alt="header"/>}
            loader={<img src={'/img/default_header.png'} height="288px" width="100%" alt="header"/>}/>
        </Grid>
    
       
      </Grid>


   
    </Box>
  )
}

Template2.propTypes = {
  /** User's data including name, skin, picture etc */
  userData: PropTypes.object.isRequired,
  /** Whether rendering as background or not, mainly to decide to show profile navbar */
  inBackground: PropTypes.bool
}

Template2.defaultProps = {
  inBackground: false
}