import React from "react";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import Nav from "../../components/Static/Nav";

import routes from "../../routes";

export default function ContentCreator({ ...rest }) {
  return (
    <div>
      <Nav headerClass="transparent-header"/>

      <div className="wf-section">
        <div className="w-container">
          <h1 className="blog-title-2">
            Can Anyone Be A Content Creator? 7 Tips On How To Get Started
          </h1>
          <div
            style={{ backgroundImage: 'url("./img/blogcoversvaporwave1.png")' }}
            className="div-block-24"
          ></div>

          <div className="content">
            <div className="rich-text-block w-richtext">
              <p>
                <em>
                  *Quick Fact* Recent data shows that micro-creatives (those in
                  the range of 3,000 - 10,000 followers) are getting the best
                  results for brands and businesses. They’re getting more
                  engagement, more clicks, and higher ROI{" "}
                </em>
                <a href="https://buffer.com/resources/micro-influencers/">
                  <em>(you can look this shit up if you don't believe us)</em>
                </a>
                <em>.&nbsp;&nbsp;&nbsp;</em>
              </p>
              <p>
                <br />
              </p>
              <p>
                Deterrents like not having much structure and being
                unappreciated, might mask that your value is actually at an
                all-time high! In addition to this, it is relatively easy to
                start creating content online as people still do want to connect
                with REAL people. Platforms like Tiktok, Snapchat, and Twitch
                have made tools for creating captivating content very
                accessible.&nbsp;
                <br />
              </p>
              <p>
                <br />
              </p>
              <figure className="w-richtext-align-center w-richtext-figure-type-image">
                <div>
                  <img
                    src="./img/61da1f7ade9f9ecc692cda02_gif.gif"
                    loading="lazy"
                    alt=""
                  />
                </div>
              </figure>
              <p>‍</p>
              <h3>
                <strong>Get into it Yuh</strong>
              </h3>
              <p>
                There are many types of Creatives, but we put together some tips
                that should apply broadly, no matter your creator niche:&nbsp;
              </p>
              <p>
                <br />
              </p>
              <p>
                <strong>Research your industry every day:</strong>
              </p>
              <ul role="list">
                <li>Stay on top of trends&nbsp;</li>
                <li>
                  Know what kind of content you feel comfortable and enjoy
                  creating and how to capitalize on new events. This will ensure
                  that the content you're creating is both relevant and high
                  quality!&nbsp;&nbsp;
                </li>
              </ul>
              <p>‍</p>
              <p>
                <strong>Create regularly:</strong>
              </p>
              <ul role="list">
                <li>
                  This means that inspiration for content is all around you! In
                  the shows you watch, the opinions of friends, nature…you name
                  it. So always be ready to write an idea down that you can come
                  back to.&nbsp;
                </li>
              </ul>
              <p>
                *
                <em>
                  This is one of the reasons you should create content based on
                  things you actually like, lol
                </em>
                *
              </p>
              <figure className="w-richtext-align-center w-richtext-figure-type-image">
                <div>
                  <img
                    src="./img/contentisking.gif"
                    loading="lazy"
                    alt=""
                  />
                </div>
              </figure>
              <p>
                <br />
              </p>
              <p>
                <strong>
                  Start creating on low-intensity platforms:&nbsp;
                </strong>
              </p>
              <ul role="list">
                <li>
                  &nbsp;Sometimes, it can be intimidating to hop on bigger
                  platforms with all the eyes and pressure to create. It is nice
                  to ease your way into content creation and test out different
                  types of content to find what works without feeling too shy.
                  So start with content that does not last forever, like IG
                  stories or Snapchat! Since this content can disappear, feel
                  free to try anything! If it doesn't work, no worries it will
                  be gone by tomorrow. If your are more of the written content
                  type, try starting with tweets. You can test how an audience
                  reacts to you, your ideas, and the voices in your head (jkjk
                  lol…unlessss - 👀 👉👈) through likes and retweets. Then
                  transition to blogs or newsletters on platforms like Substack
                  and Medium. The coolest thing about this method is that you
                  test out different types of content and also build an
                  audience!
                </li>
              </ul>
              <p>‍</p>
              <p>
                <strong>Study your audience and understand your KPI’s:</strong>
              </p>
              <ul role="list">
                <li>
                  You are a social-engineer! Learn to study your analytics from
                  these media platforms!! Try out multiple things and then look
                  at the numbers. Once you understand the engagement patterns of
                  that particular platform, you now have the power to pick what
                  type of content you want to put out and the reason; for
                  enjoyment, for engagement or both! At earlier stages, you can
                  even take this a step further. Pay attention to some of your
                  initially dedicated followers. Observe who they follow and
                  what they like and this will allow you to better cater to
                  them. Remember, if you focus on making those few followers
                  happy, millions will come.&nbsp;
                </li>
              </ul>
              <figure className="w-richtext-align-center w-richtext-figure-type-image">
                <div>
                  <img
                    src="./img/fast.gif"
                    loading="lazy"
                    alt=""
                  />
                </div>
              </figure>
              <p>
                <strong>Network at every opportunity:</strong>
              </p>
              <ul role="list">
                <li>
                  Meeting other Creatives is fantastic because it opens doors to
                  collaboration and growth for your brand. React, borrow, and
                  duet other Creative’s content. It is an excellent way to
                  network. Just be sure you give credit where credit is due!
                </li>
              </ul>
              <p>‍</p>
              <p>
                <strong>Be your favorite self at all times:</strong>
              </p>
              <ul role="list">
                <li>
                  You are more than the content you go or have gone viral for.
                  So be personable and don’t be afraid to create content that
                  shows your other interests! Your followers will resonate with
                  the authenticity, equating to more loyal fans! We know this is
                  hard as most social apps hinder free expression. However,
                  there are ways to bypass this like some app called Tagg (we’ve
                  heard its pretty fucking cool)&nbsp;
                </li>
              </ul>
              <p>‍</p>
              <p>
                <strong>Don’t feel discouraged:&nbsp;</strong>
              </p>
              <ul role="list">
                <li>
                  It’s important to remember that you are also making content
                  for yourself and you have every right to share that with the
                  world!! Being a Creative is not about the following you have,
                  it’s about creating the presence you want for yourself online.
                  Be proud of it and the fact that you're brave enough to do it!
                </li>
              </ul>
              <p>
                <br />
              </p>
              <p>
                Finally, if you’re worried about a shortage of followers in your
                desired niche we can tell you that’s ludicrous. There’s like 7
                billion people in the world bruh. Another common misconception
                is that Creatives are not fans and vice versa...The truth is
                that most Creatives are huge fans of other Creatives! And This
                Creator to Creator admiration is where collaborations stem from.
                This is literally one of the best ways to grow your brand! So go
                now - share content, meet other Creatives, and be
                unapologetically you!&nbsp;
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>**And DEFINITELY use Tagg to help you get started ;)&nbsp;</p>
              <figure className="w-richtext-align-center w-richtext-figure-type-image">
                <div>
                  <img
                    src="./img/yougotthis.gif"
                    loading="lazy"
                    alt=""
                  />
                </div>
              </figure>
              <p>
                <br />
              </p>
            </div>
          </div>
        </div>

        <div className="blog-subscribe wf-section">
          <div className="w-container">
            <div className="div-block-17">
              <div className="w-form">
                <form
                  id="email-form-2"
                  name="email-form-2"
                  data-name="Email Form 2"
                  method="get"
                  className="form"
                  aria-label="Email Form 2"
                >
                  <input
                    type="tel"
                    className="text-field-5 w-input"
                    maxlength="256"
                    name="name-3"
                    data-name="Name 3"
                    placeholder="Enter your phone number"
                    id="name-3"
                  />{" "}
                  <input
                    type="submit"
                    value="Subscribe"
                    data-wait="Please wait..."
                    className="button w-button"
                  />
                </form>
                <div
                  className="success-message-2 w-form-done"
                  tabindex="-1"
                  role="region"
                  aria-label="Email Form 2 success"
                >
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div
                  className="w-form-fail"
                  tabindex="-1"
                  role="region"
                  aria-label="Email Form 2 failure"
                >
                  <div className="text-block-13">
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-container">
            <div className="text-block-12">Download Tagg</div>
            <div className="div-block-23">
              <div className="text-block-15">
                <strong className="bold-text-5">
                  Create your profile below 👇
                </strong>
              </div>
            </div>
            <div className="div-block-30">
              <a
                href="https://apps.apple.com/us/app/tagg-powered-by-taggid-inc/id1537853613"
                target="_blank"
                className="button w-button"
              >
                Download
              </a>
            </div>
            <div className="div-block-17">
              <div className="w-form">
                <form
                  id="email-form-2"
                  name="email-form-2"
                  data-name="Email Form 2"
                  method="get"
                  className="form"
                  aria-label="Email Form 2"
                >
                  <input
                    type="tel"
                    className="text-field-5 w-input"
                    maxlength="256"
                    name="name-3"
                    data-name="Name 3"
                    placeholder="Enter your phone number"
                    id="name-3"
                  />{" "}
                  <input
                    type="submit"
                    value="Subscribe"
                    data-wait="Please wait..."
                    className="button w-button"
                  />
                </form>
                <div
                  className="success-message-2 w-form-done"
                  tabindex="-1"
                  role="region"
                  aria-label="Email Form 2 success"
                >
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div
                  className="w-form-fail"
                  tabindex="-1"
                  role="region"
                  aria-label="Email Form 2 failure"
                >
                  <div className="text-block-13">
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
